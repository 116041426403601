import React, { useEffect, useState } from "react";
import { IMAGE } from "../../assets";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const history = useNavigate();
  const location = useLocation();

  const [navItems, setNavItems] = useState<
    {
      linkNavigate: string;
      label: string;
      active: boolean;
    }[]
  >([
    {
      linkNavigate: "/energy",
      label: "Energy",
      active: false,
    },
    {
      linkNavigate: "/media-and-entertainment",
      label: "Media & Entertainment",
      active: false,
    },
    {
      linkNavigate: "/packaged-solution",
      label: "Packaged Solutions",
      active: false,
    },
    {
      linkNavigate: "/contact-us",
      label: "Contact Us",
      active: false,
    },
  ]);

  useEffect(() => {
    setNavItems(
      (
        prevNavItems: {
          linkNavigate: string;
          label: string;
          active: boolean;
        }[]
      ) => {
        const currentNavItems = prevNavItems.map((navItem) => {
          if (navItem.linkNavigate === location.pathname) {
            return { ...navItem, active: true };
          }
          return { ...navItem, active: false };
        });
        return currentNavItems;
      }
    );
  }, [location.pathname]);

  return (
    <div className="header">
      <div className="header-container">
        <div onClick={() => history(`/`)}>
          <IMAGE.CEPLogo className="header__brand" />
        </div>
        <ul className="header__nav">
          {navItems.map((navItem, index) => (
            <li className="nav__item" key={`${navItem.label}${index}`}>
              <div
                role="none"
                className="nav__item--link"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history(navItem.linkNavigate);
                }}
                style={navItem.active ? { color: "#474ed6" } : {}}
              >
                {navItem.label}
              </div>
            </li>
          ))}

          <li className="nav__item">
            <button
              className="banner--btn"
              onClick={() => history("/book-demo")}
            >
              Book a Demo
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
