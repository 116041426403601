import MainLogo from "../../assets/TQA/main-logo-tqa.svg";
import InfraDevOPSIcon from "../../assets/TQA/infra-devops.svg";
import ArrowDeliveryIcon from "../../assets/TQA/arrow-delivery-process.svg";
import ArrowEngageIcon from "../../assets/TQA/arrow-engage-model.svg";
import ArrowInfraIcon from "../../assets/TQA/arrow-infra.svg";
import ArrowManageIcon from "../../assets/TQA/arrow-manage.svg";
import ArrowPeopleIcon from "../../assets/TQA/arrow-people.svg";
import ArrowToolTechIcon from "../../assets/TQA/arrow-tool-tech.svg";
import DeliveryIcon from "../../assets/TQA/delivery-process.svg";
import EngageIcon from "../../assets/TQA/engagement-model.svg";
import ManageIcon from "../../assets/TQA/management-governance.svg";
import PeopleIcon from "../../assets/TQA/people.svg";
import ToolTechIcon from "../../assets/TQA/tool-technique.svg";
import { v4 } from "uuid";

import "./styles.scss";

interface IContentItem {
  title: string;
  logo: string;
  arrow: string;
  position: {
    top: string;
    right?: string;
    left?: string;
  };
  color: string;
  descList: string[];
  marginBottom?: string;
}

const contentLeftItemList: IContentItem[] = [
  {
    title: "Infrastructure & DevSecOps",
    logo: InfraDevOPSIcon,
    arrow: ArrowInfraIcon,
    position: { top: "30px", right: "-87px" },
    descList: [
      "Self service Portal",
      "DevSecOps Process Automation",
      "Multi cloud & Hybrid Cloud",
      "No Ops oriented",
    ],
    marginBottom: "2.395833vw",
    color: "#CF4CCD",
  },
  {
    title: "Tools & Techniques",
    logo: ToolTechIcon,
    arrow: ArrowToolTechIcon,
    position: { top: "18px", right: "-32px" },
    descList: [
      "CASE Tools",
      "Dev Tools & Software Components",
      "Solutions",
      "Case Study/Business Knowledge",
    ],
    marginBottom: "3.177083vw",
    color: "#FF7A45",
  },
  {
    title: "Delivery Process",
    logo: DeliveryIcon,
    arrow: ArrowDeliveryIcon,
    position: { top: "-15px", right: "-90px" },
    descList: [
      "Reduce cognitive load",
      "Streamlines engineering processes",
      "Improves collaboration and communication",
      "Reduces costs and time to market",
    ],
    color: "#347DEB",
  },
];

const contentRightItemList: IContentItem[] = [
  {
    title: "People",
    logo: PeopleIcon,
    arrow: ArrowPeopleIcon,
    position: { top: "30px", left: "-90px" },
    descList: [
      "Dedicated Platform Engineering Team",
      "Expertise Community (SME)",
      "Taskforce in Delivery Units",
    ],
    marginBottom: "3.6979vw",
    color: "#8436DC",
  },
  {
    title: "Engagement Model",
    logo: EngageIcon,
    arrow: ArrowEngageIcon,
    position: { top: "18px", left: "-34px" },
    descList: [
      "Simplify developer experience",
      "Smooth transition",
      "Reduce communication between platform team and development team",
      "Built in collaboration",
    ],
    marginBottom: "1.14583vw",
    color: "#28A445",
  },
  {
    title: "Management Governance",
    logo: ManageIcon,
    arrow: ArrowManageIcon,
    position: { top: "-16px", left: "-88px" },
    descList: [
      "Process & Policy",
      "KPI Metrics & Reporting",
      "Resource optimization",
      "Built-in the DevSecOps framework",
    ],
    color: "#474ED6",
  },
];

export function TQA() {
  const renderListContent = (
    contentList: IContentItem[],
    isRight?: boolean
  ) => {
    return contentList.map((item) => (
      <div
        className="content-item"
        style={{ marginBottom: item?.marginBottom || 0 }}
        key={v4()}
      >
        <h3
          className="title-content-item"
          style={{ justifyContent: isRight ? "flex-end" : "flex-start" }}
        >
          {isRight ? (
            <>
              <div className="wrapped-icon-mobile wrapped-icon">
                <img className="icon-item" src={item.logo} />
                <div className="wrapped-arrow" style={{ ...item.position }}>
                  <img src={item.arrow} alt="" />
                </div>
              </div>
              <div className="wrapped-icon-mobile">&nbsp;&nbsp;</div>
              <div className="title-item-tqa" style={{ color: item?.color }}>
                {item.title}
              </div>
              <div className="margin-item">&nbsp;&nbsp;</div>
              <div className="wrapped-icon">
                <img className="icon-item" src={item.logo} />
                <div className="wrapped-arrow" style={{ ...item.position }}>
                  <img src={item.arrow} alt="" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="wrapped-icon">
                <img className="icon-item" src={item.logo} />
                <div className="wrapped-arrow" style={{ ...item.position }}>
                  <img src={item.arrow} alt="" />
                </div>
              </div>
              &nbsp;&nbsp;
              <div className="title-item-tqa" style={{ color: item?.color }}>
                {item.title}
              </div>
            </>
          )}
        </h3>
        {item.descList.map((descItem) => (
          <div
            className="desc-item-content"
            style={{ textAlign: isRight ? "right" : "left" }}
            key={v4()}
          >
            <b>&#x2022;</b> &nbsp;{descItem}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <div id="tqa">
      <div style={{ width: "100%", height: "100%" }}>
        <div className="title-header">
          <h1 className="title">TQA - Customized Engineering Platform</h1>
          <div className="desc-title">
            <div style={{ maxWidth: 1177 }}>
              Platform engineering improves developer experience and
              productivity by providing set of reusable components &
              self-service capabilities with automated infrastructure
              operations/development processes
            </div>
          </div>
          <div className="flex-center" style={{ marginBottom: 60 }}>
            <div
              className="btn-try-now btn-try-now--dark"
              onClick={() =>
                window.open("https://tqa.cep-solution.net/", "_blank")
              }
            >
              Try Now
            </div>
          </div>
        </div>
        <div className="main-logo-mobile">
          <div>
            <img src={MainLogo} alt="main-logo" />
          </div>
        </div>
        <div className="content">
          <div className="children-content">
            <div className="left-content">
              {renderListContent(contentLeftItemList, true)}
            </div>
            <div className="main-logo">
              <img src={MainLogo} alt="main-logo" />
            </div>
            <div className="right-content">
              {renderListContent(contentRightItemList)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
