import React from "react";
import Banner from "./components/Banner/Banner";
import Achievement from "./components/Achievement/Achievement";
import Offering from "./components/Offering/Offering";
import OurExperencies from "./components/OurExperencies/OurExperencies";
import CoreServices from "./components/CoreServices/CoreServices";

export default function MediaAndEntertainment() {
  return (
    <div>
      <Banner></Banner>
      <Achievement></Achievement>
      <Offering></Offering>
      <OurExperencies></OurExperencies>
      <CoreServices />
    </div>
  );
}
