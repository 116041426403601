import { ColCustom, Container, ResponsiveContainer, RowCustom } from "./styled";

const Achievement = () => {
  const achievements = [
    { count: "25", content: "Happy Customers" },
    {
      count: "150",
      content: "Resources experience in Media & Entertainment Domain",
    },
    {
      count: "10",
      content: "Domain Subject Matter Experts",
    },
    {
      count: "10",
      content: "Years working in Domain​",
    },
  ];
  return (
    <Container>
      <ResponsiveContainer>
        <RowCustom>
          {achievements.map((achieve) => (
            <ColCustom span={6}>
              <h1>{achieve.count}+</h1>
              <div>{achieve.content}</div>
            </ColCustom>
          ))}
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};
export default Achievement;
