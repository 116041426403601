import { Col, Row } from "antd";
import {
  ColCustom,
  Container,
  CustomersLogo,
  OfferItem,
  OfferTitle,
  Offers,
  ResponsiveContainer,
  RowCustom,
  SubTitle,
  Title,
} from "./Styled";
import { IMAGE } from "../../../../assets";

const Customers = () => {
  const logosTop = [
    IMAGE.energy.customersLogo.shellLogo,
    IMAGE.energy.customersLogo.equinorLogo,
    IMAGE.energy.customersLogo.repsolLogo,
    IMAGE.energy.customersLogo.pttepLogo,
    IMAGE.energy.customersLogo.akerBPLogo,
    IMAGE.energy.customersLogo.petrobrasLogo,
  ];

  const logosBottom = [
    IMAGE.energy.customersLogo.saudiAramcoLogo,
    IMAGE.energy.customersLogo.omanPetroLogo,
    IMAGE.energy.customersLogo.woodsideLogo,
    IMAGE.energy.customersLogo.adnocLogo,
    IMAGE.energy.customersLogo.sonatrachLogo,
    IMAGE.energy.customersLogo.bpLogo,
  ];

  return (
    <Container>
      <ResponsiveContainer>
        <Title>Our Global Customers</Title>
        <RowCustom>
          <ColCustom span={11}>
            <Row>
              <Col span={10}>
                <img src={IMAGE.energy.halliburtionBigLogo} />
              </Col>
              <Col
                span={14}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <OfferTitle>10+ Years Customer</OfferTitle>
                <Offers>
                  <OfferItem>Worldwide customer based</OfferItem>
                  <OfferItem>Strategic technology partner</OfferItem>
                  <OfferItem>+300 software engineers​</OfferItem>
                </Offers>
              </Col>
            </Row>
          </ColCustom>
          <ColCustom span={11}>
            <Row>
              <Col span={10}>
                <img src={IMAGE.energy.petronasBigLogo} />
              </Col>
              <Col
                span={14}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <OfferTitle>15+ Years Customer</OfferTitle>
                <Offers>
                  <OfferItem>Multi-million US Dollar contract value</OfferItem>
                  <OfferItem>Strategic technology partner</OfferItem>
                  <OfferItem>
                    +150 software engineers onsite & offshore​
                  </OfferItem>
                </Offers>
              </Col>
            </Row>
          </ColCustom>
        </RowCustom>
        <CustomersLogo>
          <RowCustom style={{ width: "100%" }}>
            {logosTop?.map((logo) => (
              <Col
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                }}
              >
                <img src={logo} alt="" />
              </Col>
            ))}
          </RowCustom>
          <RowCustom style={{ width: "100%" }}>
            {logosBottom?.map((logo) => (
              <Col
                style={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "center",
                }}
              >
                <img src={logo} alt="" />
              </Col>
            ))}
          </RowCustom>
        </CustomersLogo>
      </ResponsiveContainer>
    </Container>
  );
};

export default Customers;
