import { Button, Form, Input } from "antd";
import styled from "styled-components";

export const StyledContainer = styled.div`
  background: linear-gradient(243.77deg, #4a57dc 25.11%, #202987 75.39%);
  height: 100vh;
  width: 100%;
  display: flex;
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftContainer = styled.div`
  width: 60%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  gap: 50px;
`;

export const RightContainer = styled.div`
  height: 100%;
  width: 40%;
  background: #fff;
  border-top-left-radius: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginContainer = styled.div`
  width: 450px;
`;

export const Title = styled.h1`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #09090a;
  text-align: center;
`;

export const InlineForgotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 140px;
  margin-bottom: 33px;
`;

export const LoginButton = styled(Button)`
  padding: 2px 20px;
  width: 376px;
  height: 48px;
  background: #5d5fef;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 19px;
  font-family: "Inter";
  font-style: normal;
`;

export const SmallText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4c5057;
  font-family: "Inter";
  font-style: normal;
  margin-right: 8px;
`;

export const SignUpText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  font-family: "Inter";
  font-style: normal;
  color: #5d5fef;
`;

export const FormItem = styled(Form.Item)`
  font-family: "Inter";
  font-style: normal;
  font-family: "Roboto";

  letter-spacing: -0.01em;
  > .ant-form-item-row {
    > .ant-form-item-label {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 120%;
      display: flex;
      align-items: center;
      > label {
        flex-flow: row-reverse;
      }
    }
  }
  .ant-input {
    border: 1px solid #ced0d6;
    border-radius: 4px;
  }
`;
