import { IMAGE } from "../../../../assets";
import {
  ColCustom,
  Container,
  OfferItem,
  OfferTitle,
  Offers,
  ResponsiveContainer,
  RowCustom,
  SubTitle,
  Title,
} from "./styled";

const Overview = () => {
  const offers = [
    {
      icon: IMAGE.energy.upstream,
      title: "Upstream",
      offerContents: [
        "Exploration​",
        "Well Construction",
        "Completions​​",
        "Production",
        "Abandonment",
      ],
    },
    {
      icon: IMAGE.energy.midstream,
      title: "Midstream",
      offerContents: ["Storage​", "Processing", "Transportation​"],
    },
    {
      icon: IMAGE.energy.downstream,
      title: "Downstream",
      offerContents: ["Refining", "Transport to retails", "Finish products​"],
    },
  ];

  return (
    <Container>
      <ResponsiveContainer>
        <Title>Overview Oil & Gas Industry</Title>
        <SubTitle>System Integration Experience in Oil & Gas Industry</SubTitle>
        <RowCustom gutter={[25, 50]}>
          {offers?.map((offer) => (
            <ColCustom span={7}>
              <img src={offer?.icon} alt=""></img>
              <OfferTitle>{offer?.title}</OfferTitle>
              <Offers>
                {offer?.offerContents?.map((content) => (
                  <OfferItem>{content}</OfferItem>
                ))}
              </Offers>
            </ColCustom>
          ))}
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};

export default Overview;
