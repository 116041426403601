import {
  FlexContainer,
  FormItem,
  LeftContainer,
  LoginButton,
  LoginContainer,
  RightContainer,
  StyledContainer,
  Title,
} from "./styled";
import BackgroundImg from "../../assets/book-demo/background.svg";
import LoginTitle from "../../assets/book-demo/logo-title.svg";
import { Col, Form, Input, message, Row, Select } from "antd";
import { countryList } from "./country";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { URL_API_BOOKING_DOMAIN } from "../../constants/apiDomain";

const BookDemo = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowDescription, setIsShowDescription] = useState(false);

  const submitLogin = () => {
    form.validateFields().then((fields) => {
      setIsLoading(true);
      axios

        .post(`${URL_API_BOOKING_DOMAIN}/v1/booking`, {
          ...fields,
        })
        .then((res) => {
          message.success("Submit successfully.");
          history("/");
        })
        .catch((e) => {
          message.error("Please try again later.");
        })
        .finally(() => setIsLoading(false));
    });
  };

  const productOptions = [
    {
      name: "Tada",
      value: "tada",
    },
    {
      name: "Mesha",
      value: "mesha",
    },
    {
      name: "TQA",
      value: "tqa",
    },
    {
      name: "Moments",
      value: "moments",
    },
    {
      name: "docAI",
      value: "doc-ai",
    },
    {
      name: "AIM",
      value: "aim",
    },
    {
      name: "Other",
      value: "other",
    },
  ];

  return (
    <StyledContainer>
      <FlexContainer>
        <LeftContainer>
          <img src={LoginTitle} style={{ maxHeight: "62px" }} alt="Title" />
          <img
            src={BackgroundImg}
            style={{ maxHeight: "600px" }}
            alt="Background Img"
          />
        </LeftContainer>
        <RightContainer>
          <LoginContainer>
            <Title>Book a Demo</Title>
            <Form form={form} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <FormItem
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your First Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter First Name" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Last Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Last Name" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <FormItem
                    name="email"
                    label="Email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                      { type: "email" },
                    ]}
                  >
                    <Input placeholder="Enter Email" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Phone Number!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Phone Number" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <FormItem
                    name="jobTitle"
                    label="Job Title"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Job Title!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Job Title" />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="companyName"
                    label="Company Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Company Name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Company Name" />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <FormItem
                    name="country"
                    label="Country"
                    rules={[
                      { required: true, message: "Please input your Country!" },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      placeholder="Select a Country"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={countryList.map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem
                    name="product"
                    label="Product"
                    rules={[
                      {
                        required: true,
                        message: "Please select product you want to book demo",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Product"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={(value) => {
                        setIsShowDescription(value === "Other");
                      }}
                      options={productOptions.map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                    />
                  </FormItem>
                </Col>
              </Row>
              {isShowDescription && (
                <Row>
                  <FormItem
                    name="description"
                    label="Tell us what's going on"
                    style={{ width: "100%" }}
                  >
                    <TextArea rows={4}></TextArea>
                  </FormItem>
                </Row>
              )}
              <LoginButton loading={isLoading} onClick={submitLogin}>
                Book Demo
              </LoginButton>
            </Form>
          </LoginContainer>
        </RightContainer>
      </FlexContainer>
    </StyledContainer>
  );
};

export default BookDemo;
