import React from "react";
import { IMAGE } from "../../../../assets";

const Contacts = () => {
  const contacts = [
    {
      icon: IMAGE.contactUs.emailUs,
      title: "Email us",
      detail: (
        <a
          href="mailto:contact.sme@fpt.com"
          className="get-in-touch-item__wrapper--detail email-us"
          style={{ color: "#474ED6", cursor: "pointer" }}
        >
          contact.sme@fpt.com
        </a>
      ),
    },
    // {
    //   icon: IMAGE.contactUs.chatUs,
    //   title: "Chat to us",
    //   detail: (
    //     <div className="get-in-touch-item__wrapper--detail">
    //       Click{" "}
    //       <span style={{ color: "#474ED6", cursor: "pointer" }}>here</span> to
    //       live chat
    //     </div>
    //   ),
    // },
    {
      icon: IMAGE.contactUs.location,
      title: "Visit Us",
      detail:
        "Lot E3, D2 street, High-tech park Long Thạnh Mỹ Ward, Thu Duc City, Vietnam",
    },
  ];
  return (
    <div className="get-in-touch-items">
      {contacts.map((contact, index) => (
        <div className="get-in-touch-item" key={index}>
          <img
            className="get-in-touch-item__icon"
            src={contact.icon}
            alt={`${contact.title}`}
          />
          <div className="get-in-touch-item__wrapper">
            <div className="get-in-touch-item__wrapper--title">
              {contact.title}
            </div>
            {contact.detail}
          </div>
        </div>
      ))}
    </div>
  );
};

export default function GetInTouch() {
  return (
    <div className="get-in-touch">
      <div className="contact-us-content-title">Get in touch</div>
      <div className="contact-us-content-desc">
        Contact us. We'll get back to you soon to arrange a time to speak.
      </div>
      <Contacts />
      <iframe
        title="Location FPT Software"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3918.6735916580396!2d106.8062000159034!3d10.836272061046756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175272fb77e7199%3A0x98cb51d4483e1172!2sFPT%20Software%20F-Town%203!5e0!3m2!1sen!2s!4v1669745281957!5m2!1sen!2s"
        width="95%"
        height="240"
        style={{ border: "1px solid #5D5FEF", borderRadius: "1.375rem" }}
        loading="lazy"
      ></iframe>
    </div>
  );
}
