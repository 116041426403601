import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 1%;
  font-family: "Poppins";
  font-style: normal;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343f4b;
`;

export const RowCustom = styled(Row)`
  width: 99%;
  justify-content: space-between;
  margin-top: 30px;
`;

export const CustomersLogo = styled(RowCustom)`
  width: 99%;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 5px 50px 10px #f0eefe;
  border-radius: 20px;
  padding: 0px 3%;
`;

export const ColCustom = styled(Col)`
  background: #ffffff;
  max-height: 220px;
  gap: 24px;
  box-shadow: 0px 13.4px 13px rgba(55, 52, 169, 0.035),
    0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815),
    0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519);
  border-radius: 20px;
  padding: 0px !important;
  font-family: "Poppins";
  font-style: normal;
  img {
    border-radius: 20px 0px 0px 20px;
    height: 220px;
    width: 170px;
  }
`;

export const OfferTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: left;
  color: #000000;
  flex: 2;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
`;

export const Offers = styled.ul`
  flex: 4;
  padding: 0px 4%;
`;

export const OfferItem = styled.li`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #465463;
  text-align: left;

  ::marker {
    color: #303dbc;
  }
`;
