import Achievement from "./components/Achievement/Achievement";
import Banner from "./components/Banner/Banner";
import CoreServices from "./components/CoreServices/CoreServices";
import Customers from "./components/Customers/Customers";
import OperationManagerment from "./components/OperationManagement/OperationManagement";
import Overview from "./components/Overview/Overview";

const Energy = () => {
  return (
    <div
      style={{ display: "flex", justifyContent: "center", flexFlow: "column" }}
    >
      <Banner></Banner>
      <Achievement></Achievement>
      <Overview></Overview>
      <CoreServices></CoreServices>
      <OperationManagerment></OperationManagerment>
      <Customers></Customers>
    </div>
  );
};

export default Energy;
