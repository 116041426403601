import React from "react";
import {
  BigBannerContent,
  CustomerBanner,
  ResponsiveContainer,
  SmallBannerContent,
} from "./styled";
import { Row } from "antd";

export default function Banner() {
  return (
    <CustomerBanner>
      <ResponsiveContainer>
        <Row style={{ justifyContent: "center", maxWidth: "1176px" }}>
          <BigBannerContent>Our Story.</BigBannerContent>
          <SmallBannerContent>
            CEP envisions a world where digital transformation empowers
            organizations to achieve their full potential
          </SmallBannerContent>
        </Row>
      </ResponsiveContainer>
    </CustomerBanner>
  );
}
