import styled from "styled-components";
import { IMAGE } from "../../../../assets";

export const CustomerBanner = styled.div`
  width: 100%;
  min-height: 480px;
  background-image: url(${IMAGE.energy.energyBg});
  color: #ffffff;
  display: flex;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  background-repeat: round;
  background-size: cover;

  color: rgb(255, 255, 255);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-repeat: round;
  background-size: cover;
`;

export const BannerTitle = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
`;

export const BannerContent = styled.div`
  justify-content: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  color: #ffffff;
`;

export const BookDemoButton = styled.button`
  margin-top: 20px;
  background: #ff7a45;
  border: none;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  height: 50px;
  padding: 2px 32px;
  gap: 4px;
`;
