import {
  Form,
  Input as AntdInput,
  Row,
  Button as ButtonAntd,
  message,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { URL_API_BOOKING_DOMAIN } from "../../../../constants/apiDomain";

const FormItem = styled(Form.Item)`
  margin-bottom: 2.5rem;
  & .ant-form-item-explain-error {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    margin-top: 0.5rem;
    padding-left: 0.5rem;
  }
`;

const Input = styled(AntdInput)`
  padding: 1.25rem 1.5rem;
  background: #ecedfd;
  border-radius: 31px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  width: 80%;
  &::placeholder {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
  }
`;

const Button = styled(ButtonAntd)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4rem;
  padding: 1rem 4rem;
  height: 100%;
  background: #474ed6;
  border-radius: 30px;
  color: #ffffff;
`;

export default function FormContact() {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    //MOCK FROM BOOK DEMO
    form.validateFields().then((fields) => {
      setIsLoading(true);
      axios
        .post(`${URL_API_BOOKING_DOMAIN}/v1/contact-us`, {
          ...fields,
        })
        .then((res) => {
          message.success("Submit successfully.");
          window.scrollTo(0, 0);
          history("/");
        })
        .catch((e) => {
          message.error("Please try again later.");
        })
        .finally(() => setIsLoading(false));
    });
  };

  return (
    <div className="form-contact">
      <div className="contact-us-content-title">How can I help you?</div>
      <div className="contact-us-content-desc">
        Let us know how we can help by filling-in the contact form.
      </div>
      <div className="form-container">
        <Form form={form}>
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input placeholder="Email *" />
          </FormItem>
          <FormItem
            name="subject"
            rules={[
              {
                required: true,
                message: "Please input your subject!",
              },
            ]}
          >
            <Input placeholder="Subject *" />
          </FormItem>
          <FormItem
            name="content"
            rules={[
              {
                required: true,
                message: "Please input your issues!",
              },
            ]}
          >
            <Input placeholder="Tell us what's going on *" />
          </FormItem>
          <Button type="primary" loading={isLoading} onClick={handleSubmit}>
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}
