import React from "react";
import "../../style.scss";

export default function Banner() {
  return (
    <div className="contact-us-banner-container">
      <div className="contact-us-banner-container__title">
        Let's start a Conversation
      </div>
    </div>
  );
}
