import React from "react";
import "./style.scss";
import PackagedSolutionCards from "./PackagedSolutionCards";
import { useNavigate } from "react-router-dom";

export default function PackagedSolution() {
  const history = useNavigate();
  return (
    <div className="packaged-solution-container">
      <div className="packaged-solution-title-wrapper">
        <div className="packaged-solution-title-wrapper__title">
          Packaged Solutions
        </div>
        <div className="packaged-solution-title-wrapper__desc">
          Streamline your business with our all-in-one packaged solution
        </div>
        <button
          className="packaged-solution-title-wrapper__button"
          onClick={() => {
            window.scrollTo(0, 0);
            history("/packaged-solution");
          }}
        >
          See all Products
        </button>
      </div>
      <div className="packaged-solution-content-container">
        <PackagedSolutionCards />
      </div>
    </div>
  );
}
