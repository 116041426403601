import React, { useEffect } from "react";
import Banner from "./components/Banner/Banner";
import AboutCEP from "./components/AboutCep/AboutCep";
import Capabilities from "./components/Capabilities/Capabilities";
import WhyCepSection from "./components/WhyCepSection/WhyCepSection";
import { useLocation } from "react-router-dom";

export default function WhyCEP() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      window.location.href = location.hash;
    }
  }, [location.hash]);
  return (
    <div>
      <Banner></Banner>
      <AboutCEP></AboutCEP>
      <Capabilities></Capabilities>
      <WhyCepSection></WhyCepSection>
    </div>
  );
}
