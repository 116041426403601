import { IMAGE } from "../../../../assets";
import {
  ColCustom,
  Container,
  ResponsiveContainer,
  RowCustom,
  Title,
} from "./styled";

const Capabilities = () => {
  return (
    <Container id="capabilities">
      <ResponsiveContainer>
        <Title>Capabilities</Title>
        <RowCustom>
          <ColCustom span={5}>
            <img src={IMAGE.cep.dataCeftificate} alt="Data Ceftificate" />
            <h1>25 +</h1>
            <div>Data Certificate</div>
          </ColCustom>
          <ColCustom span={5}>
            <img src={IMAGE.cep.outsystemsCeftificate} alt="Data Ceftificate" />
            <h1>20 +</h1>
            <div>Outsystem Certificate</div>
          </ColCustom>{" "}
          <ColCustom span={5}>
            <img src={IMAGE.cep.muleSoftCeftificate} alt="Data Ceftificate" />
            <h1>9 +</h1>
            <div>MuleSoft Certificate</div>
          </ColCustom>{" "}
          <ColCustom span={5}>
            <img src={IMAGE.cep.softwareCeftificate} alt="Data Ceftificate" />
            <h1>8 +</h1>
            <div>Software AG Certificate</div>
          </ColCustom>
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};
export default Capabilities;
