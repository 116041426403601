import React from "react";
import { IMAGE } from "../../../../assets";
import { OurExperenciesContainer } from "./Styled";

export default function OurExperencies() {
  return (
    <OurExperenciesContainer>
      <img
        src={IMAGE.mediaEntertainment.ourExperenciesBg}
        alt="our-experencies"
        width="1176px"
      />
    </OurExperenciesContainer>
  );
}
