import { Col, Row } from "antd";
import { IMAGE } from "../../../../assets";
import {
  ColCustom,
  Container,
  FreeText,
  Item,
  OfferTitle,
  ResponsiveContainer,
  RowCustom,
  Services,
  SubTitle,
  Title,
} from "./styled";

const CoreServices = () => {
  const coreServices = [
    {
      icon: IMAGE.energy.digitalTransformation,
      title: "Digital Transformation for Upstream sector ​",
      contentItem: [
        `Our SME team of 50+ experts gaining solid
        knowledge and experience through 100+ projects can provide
        a full service of consultant and digital transformation 
        to improve operation productivity of all Upstream activities.`,
      ],
    },
    {
      icon: IMAGE.energy.moderniaztion,
      title: "Modernization of Legacy Applications​",

      contentItem: [
        `Our capabilities across multiple technologies can help modernize any legacy apps to absorb new technologies to gain multiple benefits for enterprises.`,
        `Experience in developing and integrating applications on Low-Code platforms.`,
      ],
    },
    {
      icon: IMAGE.energy.dataManagement,
      title: "Data Management Platform​​",
      contentItem: [
        `Data Integration: ingests, transforms, integrates and delivers structured data to scalable data warehouse platform.`,
        `Data Engineering: develops and maintains large-scale data processing system for preparing structured and unstructured data for analytic modeling.`,
      ],
    },
    {
      title: "Software Testing​",
      icon: IMAGE.energy.softwareTesting,
      contentItem: [
        `Our software testing automation suite (MESHA) helps optimize schedule of application development and increase testing productivity.​`,
        `Easily to enable automation testing for the existing applications and systems.`,
      ],
    },
    {
      title: "DevOps Services​",
      icon: IMAGE.energy.devOpsServices,
      contentItem: [
        `To enable agile and iterative environment for project teams and help ship software faster with high quality.​`,
        `To enable automation for development process to help reduce release time, save effort and reduce human errors. ​`,
      ],
      freeText: "Our comprehensive DevOps framework can help:​",
    },
  ];
  return (
    <Container>
      <ResponsiveContainer>
        <Title>Oil & Gas Core Services</Title>
        <SubTitle>
          Focus On System Integration & Digital Transformation in Upstream
          Sector
        </SubTitle>
        <RowCustom gutter={[25, 25]} style={{ marginTop: "3%" }}>
          {coreServices?.map((coreService) => (
            <ColCustom span={8}>
              <Row style={{ width: "90%" }}>
                <Col
                  span={5}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <img src={coreService.icon} alt="icon" />
                </Col>
                <Col span={18} style={{ alignItems: "center" }}>
                  <OfferTitle>{coreService.title}</OfferTitle>
                </Col>
              </Row>
              <Row style={{ width: "90%" }}>
                {coreService?.freeText && (
                  <FreeText>{coreService.freeText}</FreeText>
                )}
                <Services>
                  {coreService?.contentItem?.map((coreItem) => (
                    <Item>{coreItem}</Item>
                  ))}
                </Services>
              </Row>
            </ColCustom>
          ))}
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};

export default CoreServices;
