import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ecedfd;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 1%;
  font-family: "Poppins";
  font-style: normal;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343f4b;
`;

export const RowCustom = styled(Row)`
  width: 99%;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ColCustom = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  align-items: center;
  padding: 32px 24px 32px 32px;
  gap: 24px;
  box-shadow: 0px 13.4px 13px rgba(55, 52, 169, 0.035),
    0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815),
    0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519);
  border-radius: 20px;

  font-family: "Poppins";
  font-style: normal;
  text-align: center;

  img {
    height: auto;
    align-self: center;
    flex: 1;
  }

  h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #474ed6;
    margin: 0px;
  }
`;

export const OfferTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #474ed6;
  flex: 2;
`;

export const Offers = styled.ul`
  width: 80%;
  flex: 4;
`;

export const OfferItem = styled.li`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #465463;
  text-align: left;

  ::marker {
    color: #303dbc;
  }
`;
