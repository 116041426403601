import { Col, Row } from "antd";
import { IMAGE } from "../../../../assets";
import {
  Container,
  ContentsContainer,
  CoreServiceItem,
  CoreServicesContent,
  ImgContainer,
  Item,
  ResponsiveContainer,
  Services,
  SubTitle,
  Title,
} from "./styled";

const CoreServices = () => {
  const coreServices = [
    {
      icon: IMAGE.mediaEntertainment.coreServicesBg1,
      content:
        "Advisory Services, Business Requirements, Product Planning, System Architecture",
    },
    {
      icon: IMAGE.mediaEntertainment.coreServicesBg2,
      content: "Cloud Strategy, Cloud Migration, Digital Transformation",
    },
    {
      icon: IMAGE.mediaEntertainment.coreServicesBg3,
      content: "Software Development Services",
    },
    {
      icon: IMAGE.mediaEntertainment.coreServicesBg4,
      content: "Reporting & Analytics",
    },
    {
      icon: IMAGE.mediaEntertainment.coreServicesBg5,
      content: "System Integration",
    },
    {
      icon: IMAGE.mediaEntertainment.coreServicesBg6,
      content: "Operations Management - Support - Monitoring, Alerting",
    },
  ];
  return (
    <Container>
      <ResponsiveContainer>
        <Title>Media & Entertainment Core Services</Title>
        <ContentsContainer>
          {coreServices.map((coreService, index) => (
            <CoreServiceItem key={index}>
              <ImgContainer
                style={{
                  backgroundImage: `url(${coreService.icon})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "100%",
                }}
              ></ImgContainer>

              <CoreServicesContent>{coreService.content}</CoreServicesContent>
            </CoreServiceItem>
          ))}
        </ContentsContainer>
      </ResponsiveContainer>
    </Container>
  );
};

export default CoreServices;
