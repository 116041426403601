import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #fbfbff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 1%;
  padding-bottom: 1%;
  font-family: "Poppins";
  font-style: normal;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
  padding: 3rem;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343f4b;
`;

export const OfferTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #474ed6;
`;

export const Services = styled.ul``;

export const Item = styled.li`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #465463;
  text-align: left;

  ::marker {
    color: #303dbc;
  }
`;

export const CoreServicesContent = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  text-align: center;
  color: #191b1f;
`;

export const ContentsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
  margin: 2rem 0;
`;

export const CoreServiceItem = styled.div`
  background: #ffffff;
  box-shadow: 0px 13.4px 13px rgba(55, 52, 169, 0.035),
    0px 5.45926px 6.51852px rgba(55, 52, 169, 0.0274815),
    0px 1.24074px 3.14815px rgba(55, 52, 169, 0.0168519);
  border-radius: 1.25rem;
  min-height: 20rem;
`;

export const ImgContainer = styled.div`
  min-height: 13.5rem;
  width: 100%;
`;
