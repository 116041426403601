import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #fbfbff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 2%;
  padding-top: 2%;
  font-family: "Poppins";
  font-style: normal;
  background: #ecedfd;
`;

export const Title = styled.div`
  width: 70%;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
  align-self: center;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const RowLevel1 = styled(Row)`
  width: 97%;
  justify-content: space-between;
  margin-top: 3%;
  align-self: center;
`;

export const Level1 = styled(Col)`
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
`;

export const Level1Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  min-height: 90px;
`;

export const RowLevel2 = styled(Row)`
  width: 97%;
  justify-content: space-between;
  align-self: center;
`;

export const Level2 = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #474ed6;
`;
export const Level2Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 0px;
  align-items: center;
  gap: 16px;
  height: 180px;
  background: #ffffff;
  border: 1px solid #8f91f5;
  border-radius: 16px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #474ed6;
`;

export const RowLevel3 = styled(Row)`
  width: 100%;
  justify-content: space-between;
  align-self: center;
  background: #c7c8fa;
  border-radius: 8px;
  padding: 20px;
`;

export const Level3 = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #262626;
`;

export const Level3Item = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #303dbc;
  min-height: 60px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #262626;
`;

export const ConnectionRow = styled(Row)`
  padding: 0px;
  width: 99%;
  justify-content: space-between;
  align-self: center;
`;

export const ConnectionCol = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

export const Connection = styled.div`
  border-left: 1.5px solid black;
  width: 1px;
  height: 1.5em;
`;
