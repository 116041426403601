import { IMAGE } from "../../../../assets";
import { ColCustom, Container, ResponsiveContainer, RowCustom } from "./styled";

const AboutCEP = () => {
  return (
    <Container id="about-cep">
      <ResponsiveContainer>
        <RowCustom gutter={[16, 16]}>
          <ColCustom span={12}>
            <img src={IMAGE.cep.cepTeamBuilding} alt="logo-client" />
          </ColCustom>
          <ColCustom span={12}>
            <h1>About CEP</h1>
            <div>
              CEP is part of FPT Software, a technology and IT services
              provider, headquartered in Vietnam with US$30 million in revenue
              and more than 500 employees. As a pioneer in digital
              transformation, CEP delivers world-class services in Energy and
              Media services, and more with strict compliance to global
              standards.
            </div>
            <div>
              It has served 50+ customers worldwide, we become a trusted partner
              of world-leading organizations, recognized as AWS’
              Well-Architected Partner, Microsoft’s Gold Certified Partner, and
              the first South East Asia-based strategic partner of Mila AI
              Research Institute.
            </div>
          </ColCustom>
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};

export default AboutCEP;
