import { useNavigate } from "react-router-dom";
import { IMAGE } from "../../assets";
import "./style.scss";

import React from "react";

const PackagedSolutionCard = ({
  card: { title, logo, desc, onClick },
}: {
  card: {
    title: string;
    logo: string;
    desc: string;
    onClick: () => void;
  };
}) => {
  const history = useNavigate();

  return (
    <div className="packaged-solution-card">
      <img className="packaged-solution-card__logo" alt={title} src={logo} />
      <div className="packaged-solution-card__title">{title}</div>
      <div className="packaged-solution-card__desc">{desc}</div>
      <div className="packaged-solution-card__explore-more">
        <div
          role="none"
          className="packaged-solution-card__explore-more--text"
          onClick={onClick}
        >
          Explore more
        </div>
        <img
          className="packaged-solution-card__explore-more--icon"
          alt="ARROW RIGHT ICON"
          src={IMAGE.banner.dashboard.industries.arrowRightIcon}
        />
      </div>
    </div>
  );
};

export default function PackagedSolutionCards() {
  const navigate = useNavigate();
  const cards = [
    {
      title: "TADA",
      logo: IMAGE.banner.tada,
      desc: "TADA is one central solution for the design, deployment, and management of AI applications. Wizard & Analytics Data Platform Unify - Analytics - All in one platform",
      onClick: () => navigate(`/packaged-solution/#tada`),
    },
    {
      title: "Mesha",
      logo: IMAGE.banner.mesha,
      desc: "Mesha is a cutting-edge, all-inclusive platform for quality management that supports teams of any size in delivering the best possible digital experiences.",
      onClick: () => navigate(`/packaged-solution/#mesha`),
    },
    {
      title: "Moments",
      logo: IMAGE.banner.moments,
      desc: "Video live streaming platform.",
      onClick: () => navigate(`/packaged-solution/#moment`),
    },
    {
      title: "TQA",
      logo: IMAGE.banner.tqa,
      desc: "By offering a collection of reusable components and self-service capabilities together with automated infrastructure operations and development processes, platform engineering enhances the developer experience and productivity.",
      onClick: () => navigate(`/packaged-solution/#tqa`),
    },
    {
      title: "DocAI",
      logo: IMAGE.banner.docAI,
      desc: "Automatic text, handwriting, and data extraction service for scanned documents. To recognize, comprehend, and extract data from forms and tables, optical character recognition (OCR) is used in a more sophisticated way.",
      onClick: () => navigate(`/packaged-solution/#doc-ai`),
    },
    {
      title: "AIM",
      logo: IMAGE.banner.aim,
      desc: "AIM is a modular AI-powered digital platform centered around engineering and inspection domain knowledge, providing access to accurate asset integrity management data to ensure compliance and safety at all asset levels.",
      onClick: () => navigate(`/packaged-solution/#aim`, { replace: true }),
    },
  ];
  return (
    <div className="packaged-solution-cards">
      {cards.map((card, index) => (
        <PackagedSolutionCard card={card} key={index} />
      ))}
    </div>
  );
}
