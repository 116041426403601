import React from "react";

const CEPLogo: React.FunctionComponent<{
  color?: string;
  className?: string;
  onClick?: () => void;
}> = ({ color, className }) => {
  return (
    <svg
      width="95"
      height="37"
      viewBox="0 0 95 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.981055 18.4C0.981055 14.9333 1.73105 11.85 3.23105 9.15C4.73105 6.41667 6.81439 4.3 9.48106 2.8C12.1811 1.26666 15.2311 0.499998 18.6311 0.499998C22.7977 0.499998 26.3644 1.6 29.3311 3.8C32.2977 6 34.2811 9 35.2811 12.8H25.8811C25.1811 11.3333 24.1811 10.2167 22.8811 9.45C21.6144 8.68333 20.1644 8.3 18.5311 8.3C15.8977 8.3 13.7644 9.21667 12.1311 11.05C10.4977 12.8833 9.68106 15.3333 9.68106 18.4C9.68106 21.4667 10.4977 23.9167 12.1311 25.75C13.7644 27.5833 15.8977 28.5 18.5311 28.5C20.1644 28.5 21.6144 28.1167 22.8811 27.35C24.1811 26.5833 25.1811 25.4667 25.8811 24H35.2811C34.2811 27.8 32.2977 30.8 29.3311 33C26.3644 35.1667 22.7977 36.25 18.6311 36.25C15.2311 36.25 12.1811 35.5 9.48106 34C6.81439 32.4667 4.73105 30.35 3.23105 27.65C1.73105 24.95 0.981055 21.8667 0.981055 18.4ZM49.067 7.75V14.85H60.517V21.45H49.067V29.15H62.017V36H40.517V0.899999H62.017V7.75H49.067ZM94.3178 12.2C94.3178 14.2333 93.8511 16.1 92.9178 17.8C91.9844 19.4667 90.5511 20.8167 88.6178 21.85C86.6844 22.8833 84.2844 23.4 81.4178 23.4H76.1178V36H67.5678V0.899999H81.4178C84.2178 0.899999 86.5844 1.38333 88.5178 2.35C90.4511 3.31666 91.9011 4.65 92.8678 6.35C93.8344 8.05 94.3178 10 94.3178 12.2ZM80.7678 16.6C82.4011 16.6 83.6178 16.2167 84.4178 15.45C85.2178 14.6833 85.6178 13.6 85.6178 12.2C85.6178 10.8 85.2178 9.71667 84.4178 8.95C83.6178 8.18333 82.4011 7.8 80.7678 7.8H76.1178V16.6H80.7678Z"
        fill={color ?? "#474ED6"}
      />
    </svg>
  );
};

export default CEPLogo;
