import { IMAGE } from "../../../../assets";
import {
  ColCustom,
  Container,
  OfferItem,
  OfferTitle,
  Offers,
  ResponsiveContainer,
  RowCustom,
  SubTitle,
  Title,
} from "./styled";

const Offering = () => {
  const offers = [
    {
      icon: IMAGE.mediaEntertainment.contentManagement,
      title: "Content Management",
      offerContents: [
        "Metadata Management",
        "CMS & Business Rules Management",
        "Localization​",
      ],
    },
    {
      icon: IMAGE.mediaEntertainment.scheduling,
      title: "Traffic, Scheduling & Program Guide",
      offerContents: [
        "Configure channels, program definition",
        "Schedule events for a broadcast day",
        "Generate playlist for channels​",
      ],
    },
    {
      icon: IMAGE.mediaEntertainment.contentDiscovery,
      title: "Content Discovery",
      offerContents: [
        "Search",
        "Recommendation",
        "Voice Search",
        "Knowledge Graph",
      ],
    },
    {
      icon: IMAGE.mediaEntertainment.voice,
      title: "Video Processing",
      offerContents: [
        "Encode & Encrypt",
        "Ads Insertion",
        "Network Configuration & Channels Setup",
      ],
    },
    {
      icon: IMAGE.mediaEntertainment.streaming,
      title: "Broadcast & Streaming",
      offerContents: [
        "Broadcast over satellites",
        "OTT Streaming over the Internet",
        "CDN",
      ],
    },
    {
      icon: IMAGE.mediaEntertainment.facingDevice,
      title: "Client Facing Devices",
      offerContents: ["STB", "Web", "Mobile", "Connected Devices"],
    },
  ];

  return (
    <Container>
      <ResponsiveContainer>
        <Title>Media & Entertainment Offering</Title>
        <SubTitle>Anytime, anywhere, any screen</SubTitle>
        <RowCustom gutter={[25, 50]}>
          {offers?.map((offer) => (
            <ColCustom span={7}>
              <img src={offer?.icon} alt=""></img>
              <OfferTitle>{offer?.title}</OfferTitle>
              <Offers>
                {offer?.offerContents?.map((content) => (
                  <OfferItem>{content}</OfferItem>
                ))}
              </Offers>
            </ColCustom>
          ))}
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};

export default Offering;
