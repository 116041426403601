import { Row, Col } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 1%;
  font-family: "Poppins";
  font-style: normal;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const RowCustom = styled(Row)`
  width: 99%;
  display: flex;
  justify-content: space-around;
  flex-flow: row;
`;

export const ColCustom = styled(Col)`
  img {
    height: auto;
  }

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.03em;
    color: #191b1f;
    margin: 0px;
  }
  div {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #64607d;
    margin-top: 10px;
  }
`;
