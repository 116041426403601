import React from "react";
import "./style.scss";
import "../../index.css";
import { IMAGE } from "../../assets";
import ValueProposition from "./components/ValueProposition";

const AIMContentItems = () => {
  const items = [
    {
      icon: IMAGE.aim.databaseThunderIcon,
      title: "Ensure Heavy Asset Integrity Enhance Asset Utilization",
      desc: (
        <div className="aim-contents-item__wrapper--desc not-list">
          AIM is an intuitive SaaS platform tha leverages the power of machine
          learning to solve critical issues and get the most out of their
          assets.
        </div>
      ),
    },
    {
      icon: IMAGE.aim.fastIcon,
      title: "Faster and Accurate Inspections",
      desc: (
        <ul className="aim-contents-item__wrapper--desc">
          <li>Full visibility of inspection and maintenance activities</li>
          <li>Optimized intervention planning</li>
          <li>Full traceability</li>
        </ul>
      ),
    },
    {
      icon: IMAGE.aim.centralineIcon,
      title: "Centralize and Organize Data",
      desc: (
        <ul className="aim-contents-item__wrapper--desc">
          <li>Track asset data in one place.</li>
          <li>
            Allow you to make informed decisions and tackle critical issues
            without delay
          </li>
          <li>
            Centralize access to documents and data sources from all disciplines
          </li>
          <li>
            Distribute documents between multiple stakeholders for review and
            approval
          </li>
        </ul>
      ),
    },
    {
      icon: IMAGE.aim.accelerateWorkplaceProductivityIcon,
      title: "Accelerate Workplace Productivity",
      desc: (
        <ul className="aim-contents-item__wrapper--desc">
          <li>
            Intelligent Automated Systems enable users to monitor and manage
            complex work sites in order to increase efficiency and safety while
            lowering the risk of costly mishaps.
          </li>
          <li>
            With the right tools, any complex project can be handled. We have
            intuitive dashboards to view the project progress. Thanks to this,
            no unnecessary delay exists.
          </li>
        </ul>
      ),
    },
    {
      icon: IMAGE.aim.performanceIcon,
      title: "Enhance Asset Health and Performance",
      desc: (
        <ul className="aim-contents-item__wrapper--desc">
          <li>
            Receive and response to safety and performance flags in real-time
          </li>
          <li>Comprehensive and reliable KPI reports</li>
          <li>Track asset failure history</li>
          <li>3D view of assets.</li>
        </ul>
      ),
    },
    {
      icon: IMAGE.aim.databaseThunderIcon,
      title: "Digital Twin - Actionable Insights",
      desc: (
        <ul className="aim-contents-item__wrapper--desc">
          <li>Locate equipment on the job site, anywhere, and at any time</li>
          <li>Respond on-time</li>
          <li>Touch to solve</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="aim-contents">
      {items.map((item, index) => (
        <div className="aim-contents-item" key={index}>
          <img
            className="aim-contents-item__icon"
            alt={item.title}
            src={item.icon}
          />
          <div className="aim-contents-item__wrapper">
            <div className="aim-contents-item__wrapper--title">
              {item.title}
            </div>
            {item.desc}
          </div>
        </div>
      ))}
    </div>
  );
};

export default function AIM() {
  return (
    <div className="aim-container" id="aim">
      <div className="aim-background">
        <div className="aim-title">AIM-Asset Intelligent Management</div>
        <div className="aim-desc">
          AIM is a modular AI-powered digital platform centered around
          engineering and inspection domain knowledge, providing access to
          accurate asset integrity management data to ensure compliance and
          safety at all asset levels.
        </div>
        <div className="flex-center">
          <div
            className="btn-try-now btn-try-now-aim--light"
            onClick={() =>
              window.open(
                "https://iam-eco.cep-solution.net/",
                "_blank"
              )
            }
          >
            Try Now
          </div>
        </div>
        <AIMContentItems />
        <ValueProposition />
      </div>
    </div>
  );
}
