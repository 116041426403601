import MomentTitle from "../../assets/moments/moment-title.svg";
import AugmentedAnalyticsIcon from "../../assets/moments/ai-augmented-analytics.svg";
import CustomerSupportIcon from "../../assets/moments/customer-support.svg";
import DeveloperPlatformIcon from "../../assets/moments/developer-platform.svg";
import OttAppIcon from "../../assets/moments/ott-app.svg";
import StreamLiveEventsIcon from "../../assets/moments/stream-live-events.svg";
import VideoMonetizationIcon from "../../assets/moments/video-monetization.svg";

import CatchPlayLogo from "../../assets/moments/catch-play.png";
import DishLogo from "../../assets/moments/dish-logo.svg";
import VuBiQuiTyLogo from "../../assets/moments/vu-logo.png";
import DirectTVLogo from "../../assets/moments/direct-tv.svg";
import NGTVLogo from "../../assets/moments/ngtv.svg";
import VerbLogo from "../../assets/moments/verb.png";
import { v4 } from "uuid";

import "./style.scss";

const contentMomentList = [
  {
    logo: OttAppIcon,
    title: "OTT App",
    desc: "Integrate live video & VODS with our APIs with no difficulty",
    key: 1,
  },
  {
    logo: VideoMonetizationIcon,
    title: "Video Monetization",
    desc: "Boost your revenue by monetizing video with Moments",
    key: 2,
  },
  {
    logo: StreamLiveEventsIcon,
    title: "Stream Live Events",
    desc: "Reliable, flawless live event streaming with top-tier CDNs",
    key: 3,
  },
  {
    logo: DeveloperPlatformIcon,
    title: "Developer Platform",
    desc: "Video APIs and SDKs for on-demand, real-time and live video",
    key: 4,
  },
  {
    logo: AugmentedAnalyticsIcon,
    title: "AI Augmented Analytics",
    desc: "Use enabling technologies to augment how people explore and analyze data in analytics and BI platforms",
    key: 5,
  },
  {
    logo: CustomerSupportIcon,
    title: "24/7 Customer Support",
    desc: "Connect with our support 24/7",
    key: 6,
  },
];
const listLogoClient = [
  { logo: DishLogo },
  { logo: VuBiQuiTyLogo },
  { logo: DirectTVLogo },
  { logo: NGTVLogo },
  { logo: CatchPlayLogo },
  { logo: VerbLogo },
];

export function Moment() {
  return (
    <div id="moment">
      <div className="moment-container">
        <div className="wrapped-title">
          <img
            src={MomentTitle}
            alt="moment-title"
            className="moment-title-icon"
          />
        </div>
        <div className="sub-title">Video live streaming platform</div>
        <div className="flex-center">
          <div
            className="btn-try-now btn-try-now--light"
            onClick={() =>
              window.open(
                "https://moments-fe.cep-solution.net/auth/login",
                "_blank"
              )
            }
          >
            Try Now
          </div>
        </div>
        <div className="content-moment">
          {contentMomentList.map((content) => {
            return (
              <div className="content-item" key={content.key}>
                <div className="wrapped-icon">
                  <img src={content.logo} alt={content.title} />
                </div>
                <div className="wrapped-text-desc">
                  <div className="child-wrapped-text-desc">
                    <h4 className="title-item">{content.title}</h4>
                    <div className="content-item-desc">{content.desc}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="logo-client">
          {listLogoClient.map((logoItem) => (
            <div className="logo-client-item" key={v4()}>
              <img src={logoItem.logo} alt="logo-client" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
