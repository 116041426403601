import { Col } from "antd";
import { IMAGE } from "../../../../assets";
import {
  Level1,
  Container,
  ResponsiveContainer,
  RowLevel1,
  Connection,
  ConnectionCol,
  RowLevel2,
  Level2,
  Title,
  RowLevel3,
  Level3,
  Level3Item,
  Level1Title,
  Level2Container,
} from "./styled";

const OperationManagerment = () => {
  const level1Items = [
    {
      title: "Exploration and Appraisal",
      backgroundColor: "#1A2CA3",
    },
    {
      title: "Concept Development",
      backgroundColor: "#303DBC",
    },
    {
      title: "Project Definition and Excecution",
      backgroundColor: "#474ED6",
    },
    {
      title: "Drilling",
      backgroundColor: "#5D5FEF",
    },
    {
      title: "Production",
      backgroundColor: "#8F91F5",
    },
  ];

  const level2Items = [
    {
      icon: IMAGE.energy.explorationStack,
      title: "Exploration Stack",
      span: 4,
    },
    {
      icon: IMAGE.energy.projectStack,
      title: "Project Stack",
      span: 9,
    },
    {
      icon: IMAGE.energy.drillingWellStack,
      title: "Drilling and Well Stack",
      span: 4,
    },
    {
      icon: IMAGE.energy.productionStack,
      title: "Production Stack",
      span: 4,
    },
  ];

  const level3Items = [
    {
      span: 4,
      items: ["Exploration Project Portal"],
    },
    {
      items: ["Workflow Design"],
      span: 9,
    },
    {
      items: [
        "Well Operation",
        "Digital Well Cementing",
        "Digital Fluid Solution",
      ],
      span: 4,
    },
    {
      items: ["Smart Stimulation", "Realtime Frack Monitoring"],
      span: 4,
    },
  ];
  return (
    <Container>
      <ResponsiveContainer>
        <Title>Focus on Digital Transformation in Upstream Sector</Title>
        <RowLevel1>
          {level1Items?.map((item) => (
            <Level1 span={4}>
              <Level1Title style={{ backgroundColor: item?.backgroundColor }}>
                {item?.title}
              </Level1Title>
              <ConnectionCol>
                <Connection />
              </ConnectionCol>
            </Level1>
          ))}
        </RowLevel1>
        <RowLevel2>
          {level2Items?.map((item) => (
            <Level2 span={item?.span}>
              <Level2Container>
                <img src={item?.icon} alt={item?.title} />
                {item?.title}
              </Level2Container>
              <ConnectionCol>
                <Connection />
              </ConnectionCol>
            </Level2>
          ))}
        </RowLevel2>

        <RowLevel3>
          {level3Items?.map((level3Item) => (
            <Level3 span={level3Item?.span}>
              {level3Item?.items?.map((item) => (
                <Level3Item>{item}</Level3Item>
              ))}
            </Level3>
          ))}
        </RowLevel3>
      </ResponsiveContainer>
    </Container>
  );
};
export default OperationManagerment;
