import CoreServiceCards from "./CoreServiceCard";
import "./style.scss";
import React from "react";

export default function CoreServices() {
  return (
    <div className="core-services-container">
      <div className="core-services-title-wrapper">
        <div className="core-services-title-wrapper__title">Core Services</div>
      </div>
      <CoreServiceCards />
    </div>
  );
}
