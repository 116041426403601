import styled from "styled-components";

export const CustomerBanner = styled.div`
  width: 100%;
  background: #1a2ca3;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-flow: row;
`;

export const ResponsiveContainer = styled(CustomerBanner)`
  flex-direction: column;
  max-width: 1176px;
  padding: 170px 0%;
`;

export const BigBannerContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
`;

export const SmallBannerContent = styled.div`
  width: 75%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #fafafa;
  margin-top: 20px;
`;
