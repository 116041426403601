import { IMAGE } from "../../assets";
import {
  ColCustom,
  Container,
  ResponsiveContainer,
  RowCustom,
  Title,
} from "./styled";

const Capabilities = () => {
  const capabilities = [
    {
      ceft: "Data Certificate",
      count: 25,
      image: IMAGE.cep.dataCeftificate,
    },
    {
      ceft: "Outsystem Certificate",
      count: 20,
      image: IMAGE.cep.outsystemsCeftificate,
    },
    {
      ceft: "MuleSoft Certificate",
      count: 9,
      image: IMAGE.cep.muleSoftCeftificate,
    },
    {
      ceft: "Software AG Certificate",
      count: 8,
      image: IMAGE.cep.softwareCeftificate,
    },
    {
      ceft: "Cloud Certificate",
      count: 25,
      image: IMAGE.cep.cloudCeftificate,
    },
    {
      ceft: "DevOps Certificate",
      count: 25,
      image: IMAGE.cep.devOpsCeftificate,
    },
    {
      ceft: "Appian Certificate",
      count: 25,
      image: IMAGE.cep.appianCeftificate,
    },
    {
      ceft: "JourneyApps Certificate",
      count: 25,
      image: IMAGE.cep.journeyAppCeftificate,
    },
  ];

  return (
    <Container id="capabilities">
      <ResponsiveContainer>
        <Title>Capabilities</Title>
        <RowCustom>
          {capabilities?.map((capability) => (
            <ColCustom span={5}>
              <img src={capability.image} alt={capability.ceft} />
              <h1>{capability?.count} +</h1>
              <div>{capability.ceft}</div>
            </ColCustom>
          ))}
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};
export default Capabilities;
