import React from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE } from "../../../../assets";

export default function Banner() {
  const history = useNavigate();
  return (
    <div className="banner-container" id="banner">
      <div className="banner__title">
        <div className="banner__title--text">
          We provide solutions through our applications
        </div>
      </div>
      <div className="banner__products">
        <img
          className="banner__products--product"
          alt="TADA LOGO"
          src={IMAGE.banner.tada}
          onClick={() => (window.location.href = "#tada")}
        />
        <img
          className="banner__products--product"
          alt="MESHA LOGO"
          src={IMAGE.banner.mesha}
          onClick={() => (window.location.href = "#mesha")}
        />
        <img
          className="banner__products--product"
          alt="TQA LOGO"
          src={IMAGE.banner.tqa}
          onClick={() => (window.location.href = "#tqa")}
        />
        <img
          className="banner__products--product"
          alt="MOMENTS LOGO"
          src={IMAGE.banner.moments}
          onClick={() => (window.location.href = "#moment")}
        />
        <img
          className="banner__products--product"
          alt="DOC AI LOGO"
          src={IMAGE.banner.docAI}
          onClick={() => (window.location.href = "#doc-ai")}
        />
        <img
          className="banner__products--product"
          alt="AIM LOGO"
          src={IMAGE.banner.aim}
          onClick={() => (window.location.href = "#aim")}
        />
      </div>
      <div className="banner__button-wrapper">
        <button
          className="banner__button-wrapper--button-book-demo"
          onClick={() => history(`/book-demo`)}
        >
          Book a Demo
        </button>
      </div>
    </div>
  );
}
