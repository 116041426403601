import React from "react";
import "./style.scss";

export interface IApplicationContent {
  iconSrc: string;
  title: string;
  description: string;
  imgSrc: string;
}

export interface IApplicationDetail {
  width: string;
  hasThumbnail: boolean;
  contents: IApplicationContent[];
}

const ApplicationDetail = ({ info }: { info: IApplicationDetail }) => {
  const { width, contents, hasThumbnail } = info;
  return (
    <div className="application-node" style={{ width: width }}>
      {contents.map((content: IApplicationContent) => {
        const { iconSrc, title, description, imgSrc } = content;
        return (
          <>
            <div className="application-node__header">
              <img
                src={iconSrc}
                alt={`${title} icon`}
                className="header--icon"
              />
              <h4 className="header--text">{title}</h4>
            </div>
            <div className="application-node__content">
              <p className="content--text">{description}</p>
              {hasThumbnail && (
                <img
                  src={imgSrc}
                  alt={`${title} thumbnail`}
                  className="content--thumbnail"
                />
              )}
            </div>
          </>
        );
      })}
    </div>
  );
};

export default ApplicationDetail;
