import MainLogo from "../../assets/DocAI/main-logo.svg";

import TrainML from "../../assets/DocAI/train-ml.svg";
import AutoExaction from "../../assets/DocAI/auto-exaction.svg";
import CustomEndless from "../../assets/DocAI/custom-endless.svg";
import DataRealtime from "../../assets/DocAI/data-real-time.svg";
import InstantAlert from "../../assets/DocAI/instant-alerts.svg";
import FlexibleDeployment from "../../assets/DocAI/flexible-deploy.svg";
import SupportMultiple from "../../assets/DocAI/support-multiple.svg";

import TrainMLShadow from "../../assets/DocAI/train-ml-shadow.svg";
import AutoExactionShadow from "../../assets/DocAI/auto-exaction-shadow.svg";
import CustomEndlessShadow from "../../assets/DocAI/custom-endless-shadow.svg";
import DataRealtimeShadow from "../../assets/DocAI/data-real-time-shadow.svg";
import InstantAlertShadow from "../../assets/DocAI/instant-alerts-shadow.svg";
import FlexibleDeploymentShadow from "../../assets/DocAI/flexible-deploy-shadow.svg";
import SupportMultipleShadow from "../../assets/DocAI/support-multiple-shadow.svg";
import LogoDocAI from "../../assets/DocAI/logo-doc-ai.svg";
import { v4 } from "uuid";

import "./styles.scss";

interface IContentItem {
  title: string;
  logo: string;
  arrow: string;
  position: {
    top: string;
    right?: string;
    left?: string;
  };
  descList: string;
  margin?: string;
  padding?: string;
  display?: string;
}

const contentLeftItemList: IContentItem[] = [
  {
    title: "Flexible Deployment",
    logo: FlexibleDeployment,
    arrow: FlexibleDeploymentShadow,
    position: { top: "30px", right: "-60px" },
    descList:
      "No matter the industry, we have got APIs ready for you. Can be deployed either on-premise or on-cloud.",
    margin: "0 0 15px 0",
  },
  {
    title: "Customize Endlessly",
    logo: CustomEndless,
    arrow: CustomEndlessShadow,
    position: { top: "18px", right: "-78px" },
    descList: "Customize document workflows to suit your business needs",
    margin: "0 0px 15px 0",
    padding: "0px 30px 0 0",
  },
  {
    title: "Support multiple languages",
    logo: SupportMultiple,
    arrow: SupportMultipleShadow,
    position: { top: "-34px", right: "-59px" },
    descList:
      "Be able to recognize and extract information in different languages (EN, FR, ML, etc.)",
  },
];

const contentRightItemList: IContentItem[] = [
  {
    title: "Validate Data Real-time",
    logo: DataRealtime,
    arrow: DataRealtimeShadow,
    position: { top: "21px", left: "-55px" },
    descList: "Validate, verify and approve data from database in real-time.",
    margin: "0 0px 15px 0",
  },
  {
    title: "No Need to Train ML",
    logo: TrainML,
    arrow: TrainMLShadow,
    position: { top: "21px", left: "-82px" },
    descList:
      "FPT-DocAI comes with pre-trained APIs so you don’t need to train ML models yourself",
    margin: "0 0px 15px 30px",
  },
  {
    title: "Instant Alerts",
    logo: InstantAlert,
    arrow: InstantAlertShadow,
    position: { top: "-32px", left: "-52px" },
    descList:
      "Get alerts on email about data mismatches and exceptions so you can follow up with customers",
  },
  {
    title: "Automate Information Extraction",
    logo: AutoExaction,
    arrow: AutoExactionShadow,
    position: { top: "-32px", left: "-52px" },
    descList:
      "Automatically extract key-value pairs from different document types, templates and layouts",
    display: "none",
  },
];

export function DocAI() {
  const renderListContent = (
    contentList: IContentItem[],
    isRight?: boolean
  ) => {
    return contentList.map((item) => (
      <div
        className={`content-item ${item.display ? "hidden-last-item" : ""}`}
        style={{
          justifyContent: isRight ? "flex-end" : "flex-start",
          margin: item.margin || "",
          padding: item.padding || "",
          display: item.display || "",
        }}
        key={v4()}
      >
        <div
          className="wrapped-icon wrapped-icon-before"
          style={{ display: isRight ? "none" : "block" }}
        >
          <img className="icon-item-ai" src={item.logo} alt="" />
          <div className="shadow-icon" style={{ ...item.position }}>
            <img src={item.arrow} alt="" />
          </div>
        </div>

        <div className="wrapped-desc-item">
          <h3
            className="title-content-item"
            style={{ justifyContent: isRight ? "flex-end" : "flex-start" }}
          >
            {item.title}
          </h3>
          <div
            className="text-desc"
            style={{ textAlign: isRight ? "right" : "left" }}
          >
            {item.descList}
          </div>
        </div>

        {isRight && (
          <div
            className="wrapped-icon wrapped-icon-after"
            style={{ display: isRight ? "block" : "none" }}
          >
            <img className="icon-item-ai" src={item.logo} alt="" />
            <div className="shadow-icon" style={{ ...item.position }}>
              <img src={item.arrow} alt="" />
            </div>
          </div>
        )}
      </div>
    ));
  };

  return (
    <div id="doc-ai">
      <div style={{ width: "100%", height: "100%" }}>
        <div className="logo-page">
          <img src={LogoDocAI} alt="" />
        </div>
        <div className="title-header">
          <h1 className="title">AI-based OCR for Form Recognition</h1>
          <div className="desc-title">
            <div style={{ maxWidth: 1199 }}>
              Service that automatically extracts text, handwriting, and data
              from scanned documents. It goes beyond simple optical character
              recognition (OCR) to identify, understand, and extract data from
              forms and tables
            </div>
          </div>
          <div className="flex-center" style={{ marginBottom: 60 }}>
            <div
              className="btn-try-now btn-try-now--dark"
              onClick={() =>
                window.open(
                  "https://tada-portal.cep-solution.net/ocr/documents",
                  "_blank"
                )
              }
            >
              Try Now
            </div>
          </div>
        </div>
        <div className="main-logo-mobile">
          <div>
            <img src={MainLogo} alt="main-logo" />
          </div>
        </div>
        <div className="content">
          <div className="children-content">
            <div className="left-content">
              {renderListContent(contentLeftItemList, true)}
            </div>
            <div className="main-logo">
              <img src={MainLogo} alt="main-logo" />
              <div className="last-item">
                <div className="content-item" key={v4()}>
                  <div className="wrapped-icon wrapped-icon-before">
                    <img className="icon-item-ai" src={AutoExaction} alt="" />
                    <div
                      className="shadow-icon"
                      style={{ top: -57, left: 29, width: 80 }}
                    >
                      <img
                        src={AutoExactionShadow}
                        alt=""
                        style={{ width: 80 }}
                      />
                    </div>
                  </div>

                  <div className="wrapped-desc-item">
                    <h3 className="title-content-item">
                      Automate Information Extraction
                    </h3>
                    <div className="text-desc">
                      Automatically extract key-value pairs from different
                      document types, templates and layouts
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="right-content">
              {renderListContent(contentRightItemList)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
