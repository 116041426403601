import React from "react";
import "./style.scss";
import { IMAGE } from "../../assets";

const CoreServicesItems = ({ items }: { items: string[] }) => (
  <div className="core-services-items">
    {items.map((item, index) => (
      <div className="core-services-item" key={`${item}${index}`}>
        <span className="dot" />
        <div className="core-services-item__content">{item}</div>
      </div>
    ))}
  </div>
);
export default function CoreServiceCards() {
  const cards = [
    {
      icon: IMAGE.banner.dashboard.coreServices.firstCoreServices,
      alt: "CORE SERVICES",
      items: [
        "Advisory Services",
        "Business Requirement",
        "Product Planning",
        "System Architecture",
      ],
    },
    {
      icon: IMAGE.banner.dashboard.coreServices.secondCoreServices,
      alt: "CORE SERVICES",
      items: ["Digital Transformation", "Cloud Migration"],
    },
    {
      icon: IMAGE.banner.dashboard.coreServices.thirdCoreServices,
      alt: "CORE SERVICES",
      items: [
        "Software Development Services",
        "System Integration",
        "Operation Management - Support & Monitoring",
      ],
    },
    {
      icon: IMAGE.banner.dashboard.coreServices.fourthCoreServices,
      alt: "CORE SERVICES",
      items: ["Analytics & Machine Learning​"],
    },
  ];
  return (
    <div className="core-services-cards">
      {cards.map((card, index) => (
        <div className="core-services-card" key={index}>
          <img src={card.icon} alt={`${card.alt}${index}`} />
          <CoreServicesItems items={card.items} />
        </div>
      ))}
    </div>
  );
}
