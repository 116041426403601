import { Col, Row } from "antd";
import {
  ColCustom,
  Container,
  ReasionContent,
  ReasionTitle,
  Reasions,
  ResionTitleColumn,
  ResponsiveContainer,
  RowCustom,
  Title,
} from "./styled";
import { IMAGE } from "../../../../assets";

const WhyCepSection = () => {
  const reasions = [
    {
      icon: IMAGE.cep.shield,
      title: "Trusted By Global Leader",
      content: `With more than 12 years of experience in software energy & media
      outsourcing services, we are trusted by global leaders for our
      commitment, quality processes, engineering competencies and
      willingness to learn fast.`,
    },
    {
      icon: IMAGE.cep.scalable,
      title: "Scaled Resources Pool",
      content: `People are prized assets in an Energy & Media knowledge industry
      such as ours. To ensure a highly motivated team, peak performance
      and to develop world-class leaders, CEP invests heavily in its
      people at all levels.`,
    },

    {
      icon: IMAGE.cep.process,
      title: "Best-in-class Process",
      content: `We are committed to providing the best in-class process
      for our clients. We are committed to providing the best in-class
      process for our clients. We are committed to providing the best in-class`,
    },
    {
      icon: IMAGE.cep.through,
      title: "Leading Through Innovation",
      content: `CEP has taken the industry lead by investing in four key areas –
      Energy & Media, which are the building blocks for technology
      products and enterprise applications of the future. Our passion
      and commitment to investing in new technologies is why innovative
      enterprises turn to us to help them develop their technology
      strategy.`,
    },
  ];

  return (
    <Container id="why">
      <ResponsiveContainer>
        <Title>Why CEP?</Title>
        <Reasions>4 Reasons to Go with Us</Reasions>
        <RowCustom gutter={[25, 50]}>
          {reasions?.map((reasion) => (
            <ColCustom span={9}>
              <Row>
                <Col span={3}>
                  <img src={reasion?.icon} alt="" />
                </Col>
                <ResionTitleColumn span={21}>
                  <ReasionTitle>{reasion?.title}</ReasionTitle>
                </ResionTitleColumn>
                <ReasionContent style={{ marginTop: "3%" }}>
                  {reasion?.content}
                </ReasionContent>
              </Row>
            </ColCustom>
          ))}
        </RowCustom>
      </ResponsiveContainer>
    </Container>
  );
};

export default WhyCepSection;
