import React, { useEffect } from "react";
import "./style.scss";
import Banner from "./components/Banner/Banner";
import Tada from "../../components/Tada/Tada";
import Mesha from "../../components/Mesha/Mesha";
import { TQA } from "../../components/TQA/TQA";
import { Moment } from "../../components/Moment/Moment";
import { DocAI } from "../../components/DocAI/DocAI";
import AIM from "../../components/AIM/AIM";
import { useLocation } from "react-router-dom";

export default function PackagedSolution() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      window.location.href = location.hash;
    }
  }, [location.hash]);

  return (
    <div className="packaged-solution-container">
      <Banner />
      <Tada />
      <Mesha />
      <TQA />
      <Moment />
      <DocAI />
      <AIM />
    </div>
  );
}
