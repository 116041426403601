// Common
import CEPLogo from "./CEPLogo";
import email from "./email.svg";

//Banner
import docAI from "./banner/doc-ai-logo.svg";
import mesha from "./banner/mesha-logo.svg";
import moments from "./banner/moments-logo.svg";
import person from "./banner/person.svg";
import tada from "./banner/tada-logo.svg";
import tqa from "./banner/tqa-logo.svg";
import aim from "./banner/aim-logo.svg";
import momentBackground from "./moments/moment-background.svg";
//Dashboard
import bgDashboard from "./banner/dashboard/bg.jpg";
//Dashboard-Customer-Logo
import astroLogo from "./banner/dashboard/astro-logo.jpg";
import capitaLandLogo from "./banner/dashboard/capita-land-logo.jpg";
import capitastarLogo from "./banner/dashboard/capitastar-logo.jpg";
import catchplayLogo from "./banner/dashboard/catchplay-logo.jpg";
import dataComLogo from "./banner/dashboard/data-com-logo.jpg";
import directvLogo from "./banner/dashboard/directv-logo.jpg";
import frequencyLogo from "./banner/dashboard/frequency-logo.jpg";
import halliburtonLogo from "./banner/dashboard/halliburton-logo.jpg";
import hoseLogo from "./banner/dashboard/hose-logo.jpg";
import vuBiquityLogo from "./banner/dashboard/vu-biquity-logo.jpg";
//Dashboard-Core-Services
import firstCoreServices from "./banner/dashboard/first-core-services.svg";
import secondCoreServices from "./banner/dashboard/second-core-services.svg";
import thirdCoreServices from "./banner/dashboard/third-core-services.svg";
import fourthCoreServices from "./banner/dashboard/fourth-core-services.svg";
//Dashboard-Industries
import leftIndustries from "./banner/dashboard/our-industries-bg-left.jpg";
import rightIndustries from "./banner/dashboard/our-industries-bg-right.jpg";
import arrowRightIcon from "./banner/dashboard/arrow-right-icon.svg";

// AIM
import aimBg from "./aim/bg.svg";
import bottomBg from "./aim/bottom-background.svg";
import monitorBg from "./aim/monitors-background.jpg";
import accelerateWorkplaceProductivityIcon from "./aim/accelerate-workplace-productivity-icon.svg";
import centralineIcon from "./aim/centraline-icon.svg";
import databaseThunderIcon from "./aim/database-thunder-icon.svg";
import fastIcon from "./aim/fast-icon.svg";
import keyBenefitIcon from "./aim/key-benefits-icon.svg";
import painPointIcon from "./aim/pain-points-icon.svg";
import performanceIcon from "./aim/performance-icon.svg";
import problemIcon from "./aim/problem-icon.svg";

// Mesha
import meshaBg from "./mesha/mesha-bg.svg";
import logoSection from "./mesha/logo-section.svg";
import customerList from "./mesha/customer-list.svg";
import icon1 from "./mesha/icon-detail-1.svg";
import icon2 from "./mesha/icon-detail-2.svg";
import icon3 from "./mesha/icon-detail-3.svg";
import icon4 from "./mesha/icon-detail-4.svg";
import icon5 from "./mesha/icon-detail-5.svg";
import icon6 from "./mesha/icon-detail-6.svg";
import icon8 from "./mesha/icon-detail-8.svg";

import customer1 from "./mesha/customer-1.png";
import customer2 from "./mesha/customer-2.svg";
import customer3 from "./mesha/customer-3.svg";
import customer4 from "./mesha/customer-4.png";
import customer5 from "./mesha/customer-5.svg";
import customer6 from "./mesha/customer-6.png";
import customer7 from "./mesha/customer-7.svg";
import customer8 from "./mesha/customer-8.svg";
import customer9 from "./mesha/customer-9.svg";

// Tada
import tadaRow from "./tada/logo.svg";
import aws from "./tada/aws.svg";
import azure from "./tada/azure.svg";
import ggCloud from "./tada/goggle-cloud.svg";
import securityIcon from "./tada/security-icon.svg";
import curveLine from "./tada/curve-line.svg";
import circleMarkIcon from "./tada/circle-mark-icon.svg";
import tadaThumbnail1 from "./tada/thumbnail-1.svg";
import tadaThumbnail2 from "./tada/thumbnail-2.svg";
import tadaThumbnail3 from "./tada/thumbnail-3.svg";
import tadaThumbnail4 from "./tada/thumbnail-4.svg";
import tadaThumbnail5 from "./tada/thumbnail-5.svg";
import tadaIcon1 from "./tada/icon-1.svg";
import tadaIcon2 from "./tada/icon-2.svg";
import tadaIcon3 from "./tada/icon-3.svg";
import tadaIcon4 from "./tada/icon-4.svg";
import tadaIcon5 from "./tada/icon-5.svg";
import tadaIcon6 from "./tada/icon-6.svg";
import tadaIcon7 from "./tada/icon-7.svg";
import tadaIcon8 from "./tada/icon-8.svg";

//media-and-entertainment
import contentManagement from "./media-entertainment/content-management.svg";
import contentDiscovery from "./media-entertainment/content-discovery.svg";
import scheduling from "./media-entertainment/scheduling.svg";
import voice from "./media-entertainment/voice.svg";
import streaming from "./media-entertainment/streaming.svg";
import facingDevice from "./media-entertainment/facing-devices.svg";
import mediaBannerBackground from "./media-entertainment/media-n-entertainment.svg";
import colChartIcon from "./media-entertainment/col-chart.png";
import moneyIcon from "./media-entertainment/money.png";
import chatIcon from "./media-entertainment/chat.png";
import direction from "./media-entertainment/direction.png";
import operationManagementBg from "./media-entertainment/operation-management.png";
import ourExperenciesBg from "./media-entertainment/our-experencies-bg.jpg";
import coreServicesBg1 from "./media-entertainment/core-services-1.jpg";
import coreServicesBg2 from "./media-entertainment/core-services-2.jpg";
import coreServicesBg3 from "./media-entertainment/core-services-3.jpg";
import coreServicesBg4 from "./media-entertainment/core-services-4.jpg";
import coreServicesBg5 from "./media-entertainment/core-services-5.jpg";
import coreServicesBg6 from "./media-entertainment/core-services-6.jpg";
// CEP
import cepTeamBuilding from "./why-cep/team-buiding-svg.svg";
import dataCeftificate from "./certificate/data-cef.svg";
import outsystemsCeftificate from "./certificate/os-ceft.svg";
import muleSoftCeftificate from "./certificate/muleSoft-ceft.svg";
import softwareCeftificate from "./certificate/software-ag-ceft.svg";
import cloudCeftificate from "./certificate/cloud-ceft.png";
import devOpsCeftificate from "./certificate/devOps-ceft.png";
import appianCeftificate from "./certificate/appican-ceft.png";
import journeyAppCeftificate from "./certificate/journeyApp-ceft.png";
import shield from "./why-cep/shield.svg";
import scalable from "./why-cep/scalable.svg";
import process from "./why-cep/process.svg";
import through from "./why-cep/through.svg";
// Energy
import energyBg from "./energy/energy-bg.svg";
import downstream from "./energy/downstream.svg";
import midstream from "./energy/midstream.svg";
import upstream from "./energy/upstream.svg";
import halliburtionBigLogo from "./energy/halliburton-big-logo.png";
import petronasBigLogo from "./energy/petronas-big-logo.png";
import akerBPLogo from "./energy/logo/akerBP.png";
import equinorLogo from "./energy/logo/equinor.png";
import petrobrasLogo from "./energy/logo/petrobras.png";
import pttepLogo from "./energy/logo/pttep.png";
import repsolLogo from "./energy/logo/repsol.png";
import saudiAramcoLogo from "./energy/logo/saudi-aramco.png";
import omanPetroLogo from "./energy/logo/oman-petro.png";
import woodsideLogo from "./energy/logo/woodside.png";
import adnocLogo from "./energy/logo/adnoc.png";
import sonatrachLogo from "./energy/logo/sonatrach.png";
import shellLogo from "./energy/logo/shell.png";
import bpLogo from "./energy/logo/bp.png";
import dataManagement from "./energy/data-management.svg";
import devOpsServices from "./energy/devOps-services.svg";
import digitalTransformation from "./energy/digital-transformation.svg";
import moderniaztion from "./energy/moderniaztion.svg";
import softwareTesting from "./energy/software-testing.svg";
import projectStack from "./energy/project-stack.svg";
import explorationStack from "./energy/exploration-stack.svg";
import productionStack from "./energy/production-stack.svg";
import drillingWellStack from "./energy/drilling-well-stack.svg";

//Contact-us
import emailUs from "./contact-us/email-icon.svg";
import chatUs from "./contact-us/chat-icon.svg";
import location from "./contact-us/location-icon.svg";

export const IMAGE = {
  CEPLogo,
  email,
  banner: {
    docAI,
    mesha,
    moments,
    person,
    tada,
    tqa,
    aim,
    dashboard: {
      bgDashboard,
      customerLogo: {
        astroLogo,
        capitaLandLogo,
        capitastarLogo,
        catchplayLogo,
        dataComLogo,
        directvLogo,
        frequencyLogo,
        halliburtonLogo,
        hoseLogo,
        vuBiquityLogo,
      },
      coreServices: {
        firstCoreServices,
        secondCoreServices,
        thirdCoreServices,
        fourthCoreServices,
      },
      industries: { rightIndustries, leftIndustries, arrowRightIcon },
    },
  },
  tada: {
    logo: tadaRow,
    aws,
    azure,
    ggCloud,
    securityIcon,
    curveLine,
    circleMarkIcon,
    thumbnail1: tadaThumbnail1,
    thumbnail2: tadaThumbnail2,
    thumbnail3: tadaThumbnail3,
    thumbnail4: tadaThumbnail4,
    thumbnail5: tadaThumbnail5,
    icon1: tadaIcon1,
    icon2: tadaIcon2,
    icon3: tadaIcon3,
    icon4: tadaIcon4,
    icon5: tadaIcon5,
    icon6: tadaIcon6,
    icon7: tadaIcon7,
    icon8: tadaIcon8,
  },
  mesha: {
    meshaBg,
    logoSection,
    customerList,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon8,
    customer1,
    customer2,
    customer3,
    customer4,
    customer5,
    customer6,
    customer7,
    customer8,
    customer9,
  },
  aim: {
    aimBg,
    bottomBg,
    monitorBg,
    accelerateWorkplaceProductivityIcon,
    centralineIcon,
    databaseThunderIcon,
    fastIcon,
    keyBenefitIcon,
    painPointIcon,
    performanceIcon,
    problemIcon,
  },
  moment: { momentBackground },
  mediaEntertainment: {
    contentManagement,
    contentDiscovery,
    scheduling,
    voice,
    facingDevice,
    streaming,
    mediaBannerBackground,
    direction,
    moneyIcon,
    colChartIcon,
    chatIcon,
    operationManagementBg,
    ourExperenciesBg,
    coreServicesBg1,
    coreServicesBg2,
    coreServicesBg3,
    coreServicesBg4,
    coreServicesBg5,
    coreServicesBg6,
  },
  cep: {
    cepTeamBuilding,
    dataCeftificate,
    outsystemsCeftificate,
    softwareCeftificate,
    muleSoftCeftificate,
    cloudCeftificate,
    devOpsCeftificate,
    appianCeftificate,
    journeyAppCeftificate,
    scalable,
    process,
    through,
    shield,
  },
  energy: {
    energyBg,
    downstream,
    midstream,
    upstream,
    halliburtionBigLogo,
    petronasBigLogo,
    dataManagement,
    devOpsServices,
    moderniaztion,
    digitalTransformation,
    softwareTesting,
    drillingWellStack,
    projectStack,
    productionStack,
    explorationStack,
    customersLogo: {
      akerBPLogo,
      equinorLogo,
      petrobrasLogo,
      pttepLogo,
      repsolLogo,
      shellLogo,
      saudiAramcoLogo,
      omanPetroLogo,
      woodsideLogo,
      adnocLogo,
      sonatrachLogo,
      bpLogo,
    },
  },
  contactUs: { emailUs, chatUs, location },
};
