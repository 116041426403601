import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ecedfd;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 1%;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const Title = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
`;

export const RowCustom = styled(Row)`
  justify-content: space-between;
  margin-top: 30px;
  gap: 30px;
`;

export const ColCustom = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  font-family: "Poppins";
  font-style: normal;
  text-align: center;

  img {
    height: auto;
    align-self: center;
  }

  h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #474ed6;
    margin: 0px;
  }
  div {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #465463;
  }
`;
