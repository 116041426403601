import styled from "styled-components";
import { IMAGE } from "../../../../assets";

export const CustomerBanner = styled.div`
  width: 100%;
  min-height: 480px;
  background-image: url(${IMAGE.mediaEntertainment.mediaBannerBackground});
  color: #ffffff;
  display: flex;
  justify-content: center;
  background-repeat: round;
  background-size: cover;
`;

export const BannerTitle = styled.div`
  width: 100%;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
`;

export const BookDemoButton = styled.button`
  margin-top: 20px;
  background: #474ed6;
  border: none;
  border-radius: 25px;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  height: 50px;
  padding: 2px 32px;
  gap: 4px;
  cursor: pointer;
`;

export const SmallBannerContent = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  letter-spacing: -0.02em;
  color: #fafafa;
  margin-top: 20px;
`;
