import React from "react";
import "./style.scss";
import { IMAGE } from "../../assets/index";

interface ICustomerImage {
  imgSrc: string;
  text: string;
}

const Mesha = () => {
  const listContents = [
    {
      isShowIcon: true,
      hasDetailList: true,
      title: "Powerful Portal for Centralization & Reusability",
      contents: [
        "Easily build complex automation scenarios.",
        "And flexibility integrate third-party and intelligence services.",
      ],
      icon: IMAGE.mesha.icon1,
    },
    {
      isShowIcon: true,
      hasDetailList: true,
      title: "Intelligently Distribute Test Case",
      contents: [
        "Easy to scale agent test.",
        "Optimize agents utilization to speed up test execution.",
        "Intelligently distribute test cases according to agent capabilities.",
        "Low infra configuration, investment saving",
      ],
      icon: IMAGE.mesha.icon2,
    },
    {
      isShowIcon: true,
      hasDetailList: false,
      title: "No Code",
      contents: [
        "Anyone can use simple English to build automation scenarios quickly with drag/drop on the intuitive interface.",
      ],
      icon: IMAGE.mesha.icon3,
    },
    {
      isShowIcon: true,
      hasDetailList: false,
      title: "Interactive Load Testing",
      contents: [
        "Not only automation testing, but also Real User Interactive Load Testing.",
      ],
      icon: IMAGE.mesha.icon4,
    },
    {
      isShowIcon: true,
      hasDetailList: false,
      title: "Record Testing",
      contents: [
        "Powerful RECORD and PLAYBACK utility for effortlessly create test case",
      ],
      icon: IMAGE.mesha.icon5,
    },
    {
      isShowIcon: true,
      hasDetailList: false,
      title: "Full-Platform Support with Mixed Platforms Capabilities",
      contents: [
        "Generate and execute tests on all OSs, browsers, and devices. Operate on both cloud and on-premise infrastructures.",
      ],
      icon: IMAGE.mesha.icon6,
    },
    {
      isShowIcon: false,
      hasDetailList: false,
      title: "",
      contents: [""],
      icon: null,
    },
    {
      isShowIcon: true,
      hasDetailList: false,
      title: "Seamless Agile & CI/CD Integration",
      contents: ["Web portal for work remotely and sharing to increase reuse."],
      icon: IMAGE.mesha.icon8,
    },
  ];

  const customerList: ICustomerImage[] = [
    {
      imgSrc: IMAGE.mesha.customer1,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer2,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer3,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer4,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer5,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer6,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer7,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer8,
      text: "HALLIBURTON",
    },
    {
      imgSrc: IMAGE.mesha.customer9,
      text: "HALLIBURTON",
    },
  ];

  return (
    <div id="mesha">
      <div className="mesha-container">
        <div className="mesha-container--top">
          <img
            className="logo-section"
            src={IMAGE.mesha.logoSection}
            alt="logo-mesha-section"
          />
          <div className="header-section">
            <h2 className="title">An all-in-one test automation solution</h2>
            <p className="sub-title">
              Mesha is an modern, comprehensive quality management platform that
              helps teams of any size deliver the highest quality digital
              experiences.
            </p>
            <div className="flex-center">
              <div
                className="btn-try-now btn-try-now--light"
                onClick={() =>
                  window.open("https://mesha.cep-solution.net/", "_blank")
                }
              >
                Try Now
              </div>
            </div>
          </div>

          <ul className="circle-container">
            {listContents?.map((item: any, index: number) => {
              if (!item?.isShowIcon) return <li />;
              return (
                <li className="item-content">
                  <img src={item?.icon} alt={`icon-${index}`} />
                  <div className="content">
                    <h6>{item?.title}</h6>
                    {item?.hasDetailList ? (
                      <ul className="description">
                        {item?.contents?.map((content: string) => (
                          <li>{content}</li>
                        ))}
                      </ul>
                    ) : (
                      <p className="description">
                        {item?.contents.length ? item?.contents[0] : ""}
                      </p>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        {/* <div className="customer-list">
                    <div className="customer-list--container">
                        <div className="customer-list--left">
                            <h3 className="count-number">25+</h3>
                            <h5 className="text">Happy Customers</h5>
                        </div>
                        <div className="customer-list--img">
                            {customerList.map((customer: ICustomerImage) => {
                                return (
                                    <div>
                                        <img src={customer.imgSrc} alt={customer.text} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

export default Mesha;
