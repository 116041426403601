import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-bottom: 1%;
  padding-top: 1%;
  font-family: "Poppins";
  font-style: normal;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
`;

export const Reasions = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343f4b;
`;

export const ResionTitleColumn = styled(Col)`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ReasionTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #191b1f;
  text-align: start;
  margin-left: 30px;
`;

export const ReasionContent = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #566879;
  text-align: start;
`;

export const RowCustom = styled(Row)`
  width: 99%;
  justify-content: space-between;
  margin-top: 30px;
`;

export const ColCustom = styled(Col)`
  display: flex;
  justify-content: start;
  flex-direction: column;

  font-family: "Poppins";
  font-style: normal;
  text-align: center;
  margin-top: 40px;
`;
