import React from "react";

import Banner from "./components/Banner/Banner";
import CoreServices from "./components/CoreServices/CoreServices";
import OurIndustries from "./components/OurIndustries/OurIndustries";
import PackagedSolution from "./components/PackagedSolution/PackagedSolution";
import Capabilities from "./components/Capabilities/Capabilities";

function Dashboard() {
  return (
    <>
      <Banner />
      <CoreServices />
      <OurIndustries />
      <PackagedSolution />
      <Capabilities />
    </>
  );
}

export default Dashboard;
