import React from "react";
import "../style.scss";
import { IMAGE } from "../../../assets";

const ValuePropositionItems = () => {
  const items = [
    {
      icon: IMAGE.aim.problemIcon,
      title: "The problem",
      desc: (
        <div className="value-proposition-item__desc not-list">
          Heavy assets in the Energy industry are not utilized to their full
          potential across their lifecycle, and available solutions today are
          very complex
        </div>
      ),
    },
    {
      icon: IMAGE.aim.painPointIcon,
      title: "Pain points",
      desc: (
        <ul className="value-proposition-item__desc">
          <li>Over design</li>
          <li>Over intervention</li>
          <li>Underutilization</li>
          <li>Unplanned shutdowns</li>
          <li>Unreliable data</li>
          <li>Siloed information</li>
          <li>Knowledge transfer</li>
        </ul>
      ),
    },
    {
      icon: IMAGE.aim.keyBenefitIcon,
      title: "Key benefits",
      desc: (
        <ul className="value-proposition-item__desc">
          <li> Improved asset utilization</li>
          <li>Reduction in future CAPEX</li>
          <li>Reduction in OPEXn</li>
          <li>Compliance, knowledge, transfer, and traceability</li>
        </ul>
      ),
    },
  ];

  return (
    <div className="value-proposition-items">
      {items.map((item, index) => (
        <div className="value-proposition-item" key={index}>
          <img
            className="value-proposition-item__icon"
            alt={item.title}
            src={item.icon}
          />
          <div className="value-proposition-item__title">{item.title}</div>
          {item.desc}
        </div>
      ))}
    </div>
  );
};

export default function ValueProposition() {
  return (
    <div style={{ display: "flex" }}>
      <div className="value-proposition-container">
        <div className="value-proposition-title">Value Proposition</div>
        <div className="value-proposition-desc">
          We provide cutting-edge artificial intelligence solutions that enable
          businesses to make smarter and faster decisions
        </div>
        <ValuePropositionItems />
      </div>
      <div className="other-bg">
        <div className="monitors-bg"></div>
      </div>
    </div>
  );
}
