import React from "react";
import {
  BannerContent,
  BannerTitle,
  BookDemoButton,
  CustomerBanner,
} from "./styled";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

export default function Banner() {
  const history = useNavigate();

  return (
    <CustomerBanner>
      <Row gutter={[50, 50]} style={{ width: "100%", maxWidth: "1176px" }}>
        <Col span={10}></Col>
        <Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <BannerTitle>Energy</BannerTitle>
            <BannerContent>
              Fuel holistic transformation driving innovation, growth and
              greater efficiency
            </BannerContent>
            <BookDemoButton onClick={() => history("/book-demo")}>
              Book a Demo
            </BookDemoButton>
          </div>
        </Col>
      </Row>
    </CustomerBanner>
  );
}
