import React from "react";
import "./style.scss";
import { IMAGE } from "../../assets";
import { useNavigate } from "react-router-dom";

const ButtonExploreMore = ({
  bg,
  redirectToUrl,
}: {
  bg?: string;
  redirectToUrl?: string;
}) => {
  const history = useNavigate();

  return (
    <button
      className="button-explore-more"
      style={bg ? { background: bg } : {}}
      onClick={() => {
        window.scrollTo(0, 0);
        history(redirectToUrl || "");
      }}
    >
      Explore more
    </button>
  );
};

export default function OurIndustries() {
  return (
    <div className="our-industries-container">
      <div className="our-industries-title-wrapper">
        <div className="our-industries-title-wrapper__title">
          Our Industries
        </div>
      </div>
      <div className="our-industries-content-container">
        <div className="oil-and-gas-card">
          <div className="oil-and-gas-card__title">OIL & GAS COMPETENCY</div>
          <div className="oil-and-gas-card__desc">
            Focus on{" "}
            <span style={{ color: "#FF7A45" }}>System Integration</span> &{" "}
            <span style={{ color: "#FF7A45" }}>Digital Transformation</span> in{" "}
            <span style={{ color: "#FF7A45" }}>Upstream</span> Sector
          </div>
          <ButtonExploreMore bg="#FF7A45" redirectToUrl="/energy" />
        </div>
        <div className="media-and-entertainment">
          <div className="media-and-entertainment__title">
            MEDIA & ENTERTAINMENT COMPETENCY
          </div>
          <div className="media-and-entertainment__desc">
            End to End Video Streaming Platform Development and Integration
          </div>
          <ButtonExploreMore
            bg="#474ED6"
            redirectToUrl="/media-and-entertainment"
          />
        </div>
        <div className="left-industries" />
        <div className="right-industries" />
      </div>
    </div>
  );
}
