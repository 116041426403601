import React from "react";
import Banner from "./components/Banner/Banner";
import "./style.scss";
import GetInTouch from "./components/GetInTouch/GetInTouch";
import FormContact from "./components/FormContact/FormContact";

export default function ContactUs() {
  return (
    <div className="contact-us-container">
      <Banner />
      <div className="contact-us-content">
        <GetInTouch />
        <FormContact />
      </div>
    </div>
  );
}
