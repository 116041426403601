import { Col, Row } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #fbfbff;
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-family: "Poppins";
  font-style: normal;
  padding: 1% 0px;
`;

export const ResponsiveContainer = styled(Container)`
  flex-direction: column;
  max-width: 1176px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
  color: #343f4b;
  flex: 1;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #343f4b;
`;

export const RowCustom = styled(Row)`
  width: 99%;
  justify-content: center;
  align-self: center;
`;

export const ColCustom = styled(Col)`
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  padding: 1%;
  gap: 24px;
  font-family: "Poppins";
  font-style: normal;
  text-align: center;

  img {
    height: auto;
    align-self: center;
  }

  h1 {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #474ed6;
    margin: 0px;
  }
  div {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    text-align: start;
    color: #465463;
    align-items: center;
  }
`;

export const OfferTitle = styled.div`
  padding: 10px;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 30px;
  color: #191b1f;
  text-align: left;
`;

export const Services = styled.ul`
  padding-inline-start: 1em;
  margin: 0px;
`;

export const Item = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #465463;
  text-align: left;
  ::marker {
    color: #303dbc;
  }
`;

export const FreeText = styled.p`
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #465463;
  text-align: left;
`;
