import React from "react";
import "./style.scss";
import { IMAGE } from "../../assets/index";
import ApplicationDetail, {
  IApplicationContent,
} from "../ApplicationDetail/ApplicationDetail";

interface IAdditionalInfo {
  count: string;
  text: string;
}

const Tada = () => {
  const architectureImgList = [
    IMAGE.tada.ggCloud,
    IMAGE.tada.azure,
    IMAGE.tada.aws,
  ];
  const applicationList = [
    {
      width: "33%",
      hasThumbnail: true,
      contents: [
        {
          iconSrc: IMAGE.tada.icon1,
          title: "Data Ingestion",
          description:
            "Collect & move your data from any source to any destination in a simple, secure and cost-effective way",
          imgSrc: IMAGE.tada.thumbnail1,
        },
      ],
    },
    {
      width: "33%",
      hasThumbnail: true,
      contents: [
        {
          iconSrc: IMAGE.tada.icon2,
          title: "Data Lake",
          description:
            "Simplify analytics on massive amounts of data to thousands of concurrent users without compromising speed, cost and security",
          imgSrc: IMAGE.tada.thumbnail2,
        },
      ],
    },
    {
      width: "33%",
      hasThumbnail: true,
      contents: [
        {
          iconSrc: IMAGE.tada.icon3,
          title: "Data Engineering",
          description:
            "Orchestrate, operationalize and automate complex data pipelines to increase efficiency and accelerate time to value",
          imgSrc: IMAGE.tada.thumbnail3,
        },
      ],
    },
    {
      width: "24%",
      hasThumbnail: true,
      contents: [
        {
          iconSrc: IMAGE.tada.icon4,
          title: "Application",
          description:
            "OCR, Predictive Maintenance, Video Analytics, Corrosion Detection…",
          imgSrc: IMAGE.tada.thumbnail4,
        },
      ],
    },
    {
      width: "24%",
      hasThumbnail: false,
      contents: [
        {
          iconSrc: IMAGE.tada.icon5,
          title: "Visualization",
          description:
            "Save time on exploratory data analysis and reporting using TADA capabilities for data profiling, statistical analysis, and charting. Visualize data with bar, line, and pie charts, box plots, 2D distributions, heat maps, tables, scatter plots, geo maps, custom web apps, and more.",
          imgSrc: "",
        },
      ],
    },
    {
      width: "24%",
      hasThumbnail: true,
      contents: [
        {
          iconSrc: IMAGE.tada.icon6,
          title: "Data Analytics",
          description:
            "Turn every kind of data into actionable insights for real business outcomes",
          imgSrc: IMAGE.tada.thumbnail5,
        },
      ],
    },
    {
      width: "24%",
      hasThumbnail: false,
      contents: [
        {
          iconSrc: IMAGE.tada.icon7,
          title: "DataOps",
          description:
            "Each TADA project has a visual flow representing the pipeline of data transformations and movement from start to finish",
          imgSrc: "",
        },
        {
          iconSrc: IMAGE.tada.icon8,
          title: "MLOps",
          description:
            "Develop, deploy, monitor, and maintain machine learning models, all in a single platform",
          imgSrc: "",
        },
      ],
    },
  ];

  const additionalStatistical: IAdditionalInfo[] = [
    {
      count: "25,000+",
      text: "Active Users",
    },
    {
      count: "25+",
      text: "Happy Customers",
    },
  ];

  return (
    <div id="tada">
      <div className="tada-container">
        <div className="tada__header">
          <img src={IMAGE.tada.logo} alt="TADA logo" className="header--img" />
          <div className="header--text">
            <h2 className="title">Wizard & Analytics Data Platform</h2>
            <p className="sub-title">
              TADA is one central solution for the design, deployment, and
              management of AI applications. Wizard & Analytics Data Platform
              Unify - Analytics - AI on one platform
            </p>
          </div>
          <div className="flex-center">
            <div
              className="btn-try-now btn-try-now--dark"
              onClick={() =>
                window.open("https://tada-portal.cep-solution.net/", "_blank")
              }
            >
              Try Now
            </div>
          </div>
        </div>
        <div className="architecture-box">
          <div className="architecture-top">
            <h4 className="architecture-top--text">
              Elastic Architecture Built for the Cloud
            </h4>
            <ul className="architecture-top--list">
              {architectureImgList.map((srcImage: string) => {
                return (
                  <li className="architecture-item">
                    <img
                      src={srcImage}
                      alt={`${srcImage}`}
                      className="architecture--img"
                    />
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="architecture-content">
            <div className="title">
              <img
                src={IMAGE.tada.securityIcon}
                alt="security icon"
                className="title--icon"
              />
              <h4 className="title--text">SECURITY</h4>
            </div>
            <div className="description">
              The simple way to manage risk with enterprise-grade security,
              including authentication with SSO and LDAP, role-based access
              control, social login, centralized management for users, and
              multiple fine-grained permissions that can operate at the user,
              connection, project, compute, and global levels.
            </div>
          </div>
        </div>
        <div className="architecture-line">
          <img src={IMAGE.tada.curveLine} alt="" className="curve-line" />
          <div className="architecture-list">
            {applicationList.map((item: any) => {
              return <ApplicationDetail info={item} />;
            })}
          </div>
        </div>
        <div className="additional">
          <p className="additional__text">
            We provide core and Service framework for An Energy in
            <span className="additional__text--strong">UAE, HAL, AVIATION</span>
          </p>
          <ul className="additional__statistical">
            {additionalStatistical.map(
              (item: IAdditionalInfo, index: number) => {
                return (
                  <>
                    <li className="statistical__item">
                      <h3 className="item--count">{item.count}</h3>
                      <p className="item--text">{item.text}</p>
                    </li>
                    {index < additionalStatistical.length - 1 && (
                      <div className="separation-line"></div>
                    )}
                  </>
                );
              }
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Tada;
