import React from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE } from "../../assets";
import "./style.scss";

const Banner = () => {
  const history = useNavigate();

  return (
    <div className="banner">
      <div className="banner-container">
        <div className="banner--content">
          <h1 className="banner--title">Where business meets IT talents</h1>
          <button className="banner--btn" onClick={() => history("/book-demo")}>
            Book a Demo
          </button>
        </div>
        <div className="banner-customers">
          <div className="banner-customers__title">
            Trusted by <span style={{ color: "#474ED6" }}>25+</span> customers
            worldwide
          </div>
          <div className="banner-customers__items">
            <div className="banner-customers__items-wrapper">
              <div className="banner-customers__items--item">
                <img
                  src={IMAGE.banner.dashboard.customerLogo.capitaLandLogo}
                  alt="CUSTOMER CAPITALAND LOGO"
                />
              </div>
              <div className="banner-customers__items--item">
                <img
                  src={IMAGE.banner.dashboard.customerLogo.vuBiquityLogo}
                  alt="CUSTOMER VU BIQUITY LOGO"
                />
              </div>
              <div className="banner-customers__items--item">
                <img
                  src={IMAGE.banner.dashboard.customerLogo.dataComLogo}
                  alt="CUSTOMER DATACOM LOGO"
                />
              </div>
              <div className="banner-customers__items--item">
                <img
                  src={IMAGE.banner.dashboard.customerLogo.halliburtonLogo}
                  alt="CUSTOMER HALLIBURTON LOGO"
                />
              </div>
              <div className="banner-customers__items--item">
                <img
                  src={IMAGE.banner.dashboard.customerLogo.astroLogo}
                  alt="CUSTOMER ASTRO LOGO"
                />
              </div>
              <div className="banner-customers__items--item">
                <img
                  src={IMAGE.banner.dashboard.customerLogo.capitastarLogo}
                  alt="CUSTOMER CAPISTAR LOGO"
                />
              </div>
            </div>

            <div className="banner-customers__items--item">
              <div className="banner-customers__items-wrapper">
                <div className="banner-customers__items--item">
                  <img
                    src={IMAGE.banner.dashboard.customerLogo.directvLogo}
                    alt="CUSTOMER DIRECTV LOGO"
                  />
                </div>
                <div className="banner-customers__items--item">
                  <img
                    src={IMAGE.banner.dashboard.customerLogo.catchplayLogo}
                    alt="CUSTOMER VU CATCHPLAY LOGO"
                  />
                </div>
                <div className="banner-customers__items--item">
                  <img
                    src={IMAGE.banner.dashboard.customerLogo.hoseLogo}
                    alt="CUSTOMER HOSE LOGO"
                  />
                </div>
                <div className="banner-customers__items--item">
                  <img
                    src={IMAGE.banner.dashboard.customerLogo.frequencyLogo}
                    alt="CUSTOMER FREQUENCY LOGO"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
