import "./styles.scss";
import { IMAGE } from "../../assets";
import { useNavigate } from "react-router-dom";

function Footer() {
  const history = useNavigate();

  const navigateTo = (destination: string) => {
    window.scrollTo(0, 0);
    history(destination);
  };

  return (
    <div className="footer">
      <div className="footer-container-content">
        <div className="footer-content">
          <div className="footer-content__left">
            <div onClick={() => navigateTo(`/`)}>
              <IMAGE.CEPLogo className="cep-logo" color="#fff" />
            </div>
            <div className="footer-content__left--desc">
              As a pioneer in digital transformation, CEP delivers world-class
              services in Energy and Media services, and more with strict
              compliance to global standards.
            </div>
          </div>
          <div className="footer-content__right">
            {/* <div className="footer-content-nav">
              <div
                className="footer-content-nav__title"
                onClick={() => navigateTo(`/why-cep`)}
              >
                WHY CEP?
              </div>
              <div className="footer-content-nav__items">
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/why-cep#about-cep`)}
                >
                  About CEP
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/why-cep#capabilities`)}
                >
                  Capabilities
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/why-cep#capabilities`)}
                >
                  Why CEP
                </div>
              </div>
            </div> */}
            <div className="footer-content-nav">
              <div className="footer-content-nav__title">INDUSTRIES</div>
              <div className="footer-content-nav__items">
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/energy`)}
                >
                  Energy
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/media-and-entertainment`)}
                >
                  Media
                </div>
              </div>
            </div>
            <div className="footer-content-nav">
              <div
                className="footer-content-nav__title"
                onClick={() => navigateTo(`/packaged-solution`)}
              >
                PACKAGED SOLUTIONS
              </div>
              <div className="footer-content-nav__items footer-content-nav__items-packaged-solutions">
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/packaged-solution#tada`)}
                >
                  Tada
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/packaged-solution#moment`)}
                >
                  Moments
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/packaged-solution#mesha`)}
                >
                  Mesha
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/packaged-solution#doc-ai`)}
                >
                  DocAI
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/packaged-solution#tqa`)}
                >
                  TQA
                </div>
                <div
                  className="footer-content-nav__items--item"
                  onClick={() => navigateTo(`/packaged-solution#aim`)}
                >
                  AIM
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container-copy-right">
        <div className="footer-copy-right">
          <div>Copyright by CEP - FPT Software. All rights reserved.</div>
          <div className="footer-email-wrap">
            <img src={IMAGE.email} alt="EMAIL" />
            Email us: contact.sme@fpt.com
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
