import React from "react";
import { BannerTitle, BookDemoButton, CustomerBanner } from "./styled";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

export default function Banner() {
  const history = useNavigate();

  return (
    <CustomerBanner>
      <Row gutter={[16, 16]} style={{ width: "100%", maxWidth: "1176px" }}>
        <Col span={10}></Col>
        <Col
          span={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div>
            <BannerTitle>Media & Entertainment Solutions</BannerTitle>
            <BookDemoButton onClick={() => history("/book-demo")}>
              Book a Demo
            </BookDemoButton>
          </div>
        </Col>
      </Row>
    </CustomerBanner>
  );
}
