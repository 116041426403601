import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Dashboard from "./Dashboard";
import BookDemo from "./screens/BookDemo/index";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MediaAndEntertainment from "./screens/MediaAndEntertainment/MediaAndEntertainment";
import WhyCEP from "./screens/WhyCEP/WhyCep";
import Energy from "./screens/Energy";
import ContactUs from "./screens/ContactUs/ContactUs";
import PackagedSolution from "./screens/PackagedSolution/PackagedSolution";
import { TQA } from "./components/TQA/TQA";

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/why-cep" element={<WhyCEP />} />
          <Route path="/energy" element={<Energy />} />
          <Route
            path="/media-and-entertainment"
            element={<MediaAndEntertainment />}
          />
          <Route
            path="/packaged-solution/:id?"
            element={<PackagedSolution />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
        </Route>
        <Route path="/book-demo" element={<BookDemo />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
